import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";

import headerImage from "../assets/images/faq/header.png";
import svg from "../assets/images/faq/svg.png";
import FaqList from "../components/FaqList";

const Faq = () => {
  const [selectedSection, setSelectedSection] = useState("influencer");
  const [selectedSectionFAQ, setSelectedSectionFAQ] = useState("affiliate");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Navbar isHaveBackground />
      <div
        id="home"
        className="relative h-screen bg-cover"
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: `center`,
        }}
      >
        <div className="flex h-full items-center justify-center">
          <h1 className="text-3xl font-bold text-white lg:text-center lg:text-5xl">
            FAQ
          </h1>
        </div>
      </div>
      <div className="relative -mt-9 sm:-mt-14 md:-mt-20 xl:-mt-24">
        <div className="absolute left-0 right-0 z-10">
          <img
            src={svg}
            className="h-48 w-full sm:h-72 md:h-[450px] xl:h-[600px] xl:max-h-[600px]"
            alt="svg"
          />
        </div>
        <div className="relative z-20 mx-4 pt-16 lg:container sm:mx-8 sm:pt-44 md:pt-[278px] lg:mx-auto lg:px-4 xl:pt-96">
          <div className="flex flex-col gap-6 sm:mx-auto sm:max-w-[640px] sm:flex-row sm:justify-center sm:gap-20 xl:max-w-[680px]">
            <button
              id="influencer"
              className={`w-full rounded-full border-4 border-[#9900EF] ${selectedSection === "influencer" ? "bg-[#9900EF] text-white" : "bg-white text-[#9900EF]"} px-2 py-4 transition duration-150 ease-in hover:bg-[#9900EF] hover:text-white sm:w-96 sm:rounded-3xl md:rounded-[32px] md:px-3 md:py-5`}
              onClick={() => setSelectedSection("influencer")}
            >
              <p className="font-poppins text-base font-semibold md:text-2xl lg:text-2xl">
                untuk Influencer
              </p>
            </button>
            {/* <button
              id="brand"
              className={`w-full rounded-full border-4 border-purple-500 px-2 py-4 transition duration-150 ease-in ${selectedSection === "brand" ? "bg-[#9900EF] text-white" : "bg-white text-black/60"} hover:bg-[#9900EF] hover:text-white sm:w-96 sm:rounded-3xl md:rounded-[32px] md:px-3 md:py-5 lg:hover:bg-[#9900EF]`}
              onClick={() => setSelectedSection("brand")}
            >
              <p className="text-base font-semibold md:text-2xl lg:text-2xl">
                untuk pemilik Brand
              </p>
            </button> */}
          </div>
        </div>
      </div>

      <div className="mt-20 lg:container sm:mx-8 lg:mx-auto lg:px-4 xl:mt-32 xl:max-w-4xl">
        {selectedSection === "influencer" && (
          <div className="md:flex md:gap-10">
            <div className="flex flex-col items-start">
              <button
                className={`rounded-br-xl rounded-tr-xl ${selectedSectionFAQ === "affiliate" ? "bg-[#623FAB] text-white" : "bg-white text-black/50"} w-[240px] px-8 py-4 text-left sm:rounded-xl`}
                onClick={() => setSelectedSectionFAQ("affiliate")}
              >
                <p className="text-lg font-medium">Youtz Affiliate</p>
              </button>

              {/* <button
                className={`rounded-br-xl rounded-tr-xl ${selectedSectionFAQ === "marketplace" ? "bg-[#623FAB] text-white" : "bg-white text-black/50"} w-[240px] px-8 py-4 sm:rounded-xl`}
                onClick={() => setSelectedSectionFAQ("marketplace")}
              >
                <p className="text-lg font-medium">Youtz Marketplace</p>
              </button> */}
            </div>

            <div className="mx-4 mt-8 sm:mx-0 md:mt-0">
              <FaqList />
            </div>
          </div>
        )}

        {selectedSection === "brand" && (
          <div className="section-content">
            {/* Konten untuk Brand */}
            <h2 className="my-8 text-3xl font-bold">
              Konten untuk pemilik Brand
            </h2>
            <p>Ini adalah konten yang ditujukan untuk pemilik brand...</p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Faq;
