import React from "react";
import youtzMediaLogo from "../assets/images/youtzmedia.png";
import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia";

const Footer = () => {
  return (
    <>
      <div
        id="footer"
        className="mt-20 bg-gradient-to-tr from-[#9B4BB7] to-[#3E87C3] font-montserrat text-white lg:mt-40"
      >
        <div className="mx-4 py-12 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <div className="flex flex-col items-start justify-between lg:flex-row">
            <div className="mb-5 lg:mt-0 lg:w-[30%]">
              <img
                src={youtzMediaLogo}
                className="mb-6 h-20 lg:w-24"
                alt="icon"
              />
              <SocialMedia />
            </div>
            <div className="mt-6 flex flex-col lg:mt-0 lg:w-[25%]">
              <div className="mb-2 font-medium">COMPANY</div>
              <div className="flex flex-col gap-3">
                <Link
                  className="opacity-75 hover:opacity-60"
                  to={"/influencer"}
                >
                  Influencer
                </Link>
                <Link className="opacity-75 hover:opacity-60">
                  Jasa website
                </Link>
                <Link className="opacity-75 hover:opacity-60">
                  Handling social media
                </Link>
                <Link className="opacity-75 hover:opacity-60">
                  Production house
                </Link>
                <Link className="opacity-75 hover:opacity-60">
                  Event organizer
                </Link>
              </div>
            </div>
            <div className="mt-6 flex flex-col lg:mt-0 lg:w-[20%]">
              <div className="mb-2 font-medium">SUPPORT</div>
              <div className="flex flex-col gap-3">
                <Link className="opacity-75 hover:opacity-60" to={"/faq"}>
                  FAQ
                </Link>
                <Link className="opacity-75 hover:opacity-60">Kontak</Link>
              </div>
            </div>
            <div className="mt-6 lg:mt-0 lg:w-[25%]">
              <p className="mb-2 font-medium">Location</p>
              <p className="opacity-75">
                Jl. Cendrawasih VI, RT.004/RW.003, Sawah Baru, Kec. Ciputat,
                Kota Tangerang Selatan, Banten 15413
              </p>
            </div>
          </div>
          <hr className="my-6" />
          <p>Copyright &copy; PT Kreasi Potensi Indonesia</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
