import { useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";
import Crown from "../assets/images/logo/crown.png";
import FollowersAndEngagmenet from "../components/FollowersAndEngagment";
import {
  formatCategory,
  gateway,
  socialMedia,
  sortInfluencerFilter,
} from "../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import DataNotFound from "../components/DataNotFound";

const InfluencerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultId = {
    category: "1e2580e621601d9afb7a9243f6ab6946",
    province: "837b0f2ddfczccsfad68cef5b30a9",
  };
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [detailCategory, setDetailCategory] = useState({
    name: "",
    description: "",
  });
  const [influencerName, setInfluencerName] = useState(
    queryParams.get("name") || "",
  );
  const [tempCategories, setTempCategories] = useState(categories);
  const [provinces, setProvinces] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [isOpenSearchCategory, setIsOpenSearchCategory] = useState(false);
  const [isOpenSetFilter, setIsOpenSetFilter] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    firstPageURL: "",
    lastPage: 1,
    lastPageURL: "",
    prevPageURL: "",
    nextPageURL: "",
    totalPage: 0,
  });

  // State untuk menampung data influencer
  const [kol, setKOL] = useState([]);
  const [tempKOL, setTempKOL] = useState(kol);

  // Set filter
  // Aktif filter ketika di klik
  const [selectedYoutzPrivilege, setSelectedYoutzPrivilege] = useState(
    queryParams.get("privilege"),
  );
  const [selectedPlatform, setSelectedPlatform] = useState(
    queryParams.get("platform"),
  );
  const [selectedProvince, setSelectedProvince] = useState(
    queryParams.get("province"),
  );
  // Set data filter
  const [filterOrder, setFilterOrder] = useState(
    queryParams.get("sort") || "urutkan",
  );

  useEffect(() => {
    window.scroll(0, 0);

    // Init dapetin semua provinsi
    const initProvinces = async () => {
      const data = await gateway(
        `jasa_kreatif/influencer/provinsi/${defaultId.province}`,
        "POST",
        {},
      );
      setIsLoading(false);
      if (!data) return;

      setProvinces(data.list);
    };

    initProvinces();
  }, []);

  const getDataInfluencer = async (
    id = defaultId.category,
    param = "kategori",
    body = {},
  ) => {
    setIsLoading(true);

    try {
      const data = await gateway(
        `jasa_kreatif/influencer/${param}/${id}`,
        "POST",
        body,
      );

      const { kol, platform, list } = data;
      if (!data) return;

      setKOL(kol.data);
      setTempKOL(kol.data);
      setPagination({
        currentPage: kol.current_page,
        firstPageURL: kol.first_page_url,
        lastPage: kol.last_page,
        lastPageURL: kol.last_page_url,
        prevPageURL: kol.prev_page_url,
        nextPageURL: kol.next_page_url,
        totalPage: kol.total,
      });

      const { nama_persona, deskripsi_persona } =
        param !== "provinsi" ? data.persona : data.detail_persona;

      setPlatforms(platform);
      setDetailCategory({
        name: nama_persona,
        description: deskripsi_persona,
      });

      if (param !== "provinsi") {
        setCategories(list);
      } else {
        setProvinces(list);
        setCategories(data.persona);
      }
    } catch (error) {
      console.error(`Error mengambil data influencer: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!location.search) {
      getDataInfluencer();
      return;
    }

    const category = queryParams.get("category");
    const province = queryParams.get("province");
    const platform = queryParams.get("platform");
    const page = queryParams.get("page");
    const sort = queryParams.get("sort");
    const privilege = queryParams.get("privilege");
    const name = queryParams.get("name");

    if (
      !category &&
      !province &&
      !platform &&
      !page &&
      !privilege &&
      !sort &&
      !name
    ) {
      getDataInfluencer();
      return;
    } else {
      const idCategory = category || defaultId.category;
      getDataInfluencer(idCategory, "kategori", {
        province,
        platform,
        page,
        sort,
        privilege,
        name,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (isOpenSetFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }, [isOpenSetFilter]);

  // Ubah kategori
  const changeCategory = (event) => {
    const idCategory = event.target.value;
    queryParams.set("category", idCategory);
    queryParams.delete("page");
    navigate(`?${queryParams.toString()}`, { replace: true });

    // Tutup popup pemilihan kategori ketika sudah berhasil memilih
    setIsOpenSearchCategory(false);
  };

  // Cari kategori
  const setValueCategories = (event) => {
    const value = event.target.value.toLowerCase();
    if (value.length >= 3) {
      const filterCategory = categories.filter((category) =>
        category["nama_persona"].toLowerCase().includes(value),
      );
      setTempCategories(filterCategory);
    } else {
      setTempCategories(categories);
    }
  };

  // Cari influencer berdasarkan nama
  const searchInfluencerByName = (event) => {
    event.preventDefault();
    if (influencerName) {
      queryParams.set("name", influencerName);
    } else {
      queryParams.delete("name"); // Hapus jika kosong
    }

    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  // Memanggil data berdasarkan parameter page
  const prevOrNextPaginationDataInfluencer = (isPrev = true) => {
    queryParams.set(
      "page",
      isPrev ? pagination.currentPage - 1 : pagination.currentPage + 1,
    );

    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  const lastPaginationDataInfluencer = () => {
    queryParams.set("page", pagination.lastPage);
    getDataInfluencer(
      queryParams.get("category") || defaultId.category,
      "kategori",
      {
        province: queryParams.get("province"),
        platform: queryParams.get("platform"),
        sort: queryParams.get("sort"),
        privilege: queryParams.get("privilege"),
        page: pagination.lastPage,
      },
    );
    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  // Buka set filter
  const showPopupSetFilter = () => {
    if (!isLoading) setIsOpenSetFilter(true);
  };

  // Tutup set filter
  const closePopupSetFilter = () => {
    setIsOpenSetFilter(false);
  };

  // Pilih platform pada set filter
  const selectPlatform = ({ target }) => {
    const idPlatform = target.value;
    setSelectedPlatform(idPlatform);
  };

  // Pilih provinsi pada set filter
  const selectProvince = ({ target }) => {
    const idProvince = target.value;
    setSelectedProvince(idProvince);
  };

  const resetFilter = () => {
    setFilterOrder("urutkan");
    setSelectedPlatform("");
    setSelectedPlatform("");
    setSelectedProvince("");
  };

  // Tampilkan data filter yang dipilih oleh user
  const changeDataFilter = async () => {
    // Tambahkan atau update parameter baru
    if ("sort" && filterOrder !== "urutkan") {
      queryParams.set("sort", filterOrder);
    } else {
      queryParams.delete("sort"); // Hapus jika kosong
    }

    if (selectedYoutzPrivilege) {
      queryParams.set("privilege", selectedYoutzPrivilege);
    } else {
      queryParams.delete("privilege"); // Hapus jika kosong
    }

    if (selectedProvince) {
      queryParams.set("province", selectedProvince);
    } else {
      queryParams.delete("province"); // Hapus jika kosong
    }

    if (selectedPlatform) {
      queryParams.set("platform", selectedPlatform);
    } else {
      queryParams.delete("platform"); // Hapus jika kosong
    }

    // Update URL tanpa refresh
    navigate(`?${queryParams.toString()}`, { replace: true });

    setIsOpenSetFilter(false);
  };

  return (
    <>
      <Navbar />
      <section
        className={`bg-${formatCategory(detailCategory.name)} relative flex h-screen items-center justify-center overflow-hidden bg-cover bg-no-repeat before:absolute before:inset-0 before:left-0 before:top-0 before:block before:h-full before:w-full before:bg-gradient-to-tr before:from-[#CD5AE0bb] before:via-[#A478C788] before:to-[#446DD4bb]`}
      >
        <div className="relative mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <div className="mb-7">
            <div className="relative sm:mx-auto sm:max-w-[480px]">
              <button
                type="button"
                className={`flex w-full items-center justify-center gap-4 border-4 border-white bg-transparent p-4 text-center text-2xl font-medium capitalize text-white outline-none sm:px-10 sm:text-3xl ${isOpenSearchCategory ? "rounded-tl-3xl rounded-tr-3xl" : "rounded-3xl"}`}
                onClick={() => {
                  setIsOpenSearchCategory((prev) => !prev);
                  setTempCategories(categories);
                }}
              >
                {isLoading ? (
                  <Loader className={"h-9 w-9"} />
                ) : (
                  detailCategory.name
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  fill="rgba(255,255,255,1)"
                  className={`${isOpenSearchCategory ? "rotate-180" : ""}`}
                >
                  <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
                </svg>
              </button>

              {isOpenSearchCategory && (
                <div className="absolute w-full bg-white py-4">
                  <div className="relative flex items-center px-4">
                    <input
                      type="text"
                      placeholder="Cari kategori"
                      className="w-full rounded-full border border-gray-500 py-3 pl-5 pr-14 text-xl"
                      onChange={setValueCategories}
                    />
                    <button
                      type="button"
                      className="absolute right-8 hover:opacity-70"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="28"
                        height="28"
                        fill="rgba(85,85,85,1)"
                      >
                        <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
                      </svg>
                    </button>
                  </div>

                  <div className="mt-6">
                    <div className="max-h-72 overflow-auto">
                      {tempCategories.length > 0 ? (
                        tempCategories.map((category) => {
                          const { id_persona, nama_persona } = category;

                          return (
                            <div key={id_persona}>
                              <button
                                className={`w-full border-t border-gray-300 px-4 py-3 text-left hover:bg-gray-300 ${nama_persona.toLowerCase() === detailCategory["name"].toLowerCase() ? "bg-gray-300 text-primary" : "text-gray-400"} capitalize`}
                                value={id_persona}
                                onClick={changeCategory}
                              >
                                {nama_persona}
                              </button>
                            </div>
                          );
                        })
                      ) : (
                        <DataNotFound
                          className={"py-3 text-center text-gray-300"}
                          text="Kategori tidak ditemukan"
                        />
                      )}
                    </div>
                    <p className="border-t border-gray-300 px-4 pt-3 text-sm text-primary">
                      Ketik minimal 3 huruf
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <h1 className="text-center text-3xl font-bold text-white lg:text-5xl">
                Influencer {detailCategory.name}
              </h1>
              <p className="mx-auto mt-7 max-w-6xl text-center text-base text-white lg:text-xl">
                {detailCategory.description}
              </p>
            </>
          )}
        </div>
      </section>

      <section className="mx-4 mt-10 lg:container sm:mx-8 lg:mx-auto lg:px-4 xl:mt-24">
        {/* Filter influencer */}
        <div className="grid gap-4 sm:grid-cols-[1fr_0.4fr] sm:gap-8 md:gap-12">
          <form
            onSubmit={searchInfluencerByName}
            className="relative flex w-full items-center"
          >
            <input
              type="text"
              placeholder={`Cari influencer ${detailCategory.name}`}
              className={`w-full rounded-full border border-slate-300 py-4 pl-7 pr-14 ${isLoading && "cursor-not-allowed"}`}
              onChange={({ target }) => setInfluencerName(target.value)}
              disabled={isLoading}
              value={influencerName}
            />
            <button type="submit" className="absolute right-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="rgba(173,184,194,1)"
              >
                <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
              </svg>
            </button>
          </form>
          <div
            className={`relative flex w-full items-center ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={showPopupSetFilter}
          >
            <span
              type="text"
              className="w-full rounded-full border border-slate-300 py-4 pl-7 pr-14 text-slate-400"
            >
              Set filter
            </span>
            <button type="button" className="absolute right-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="rgba(173,184,194,1)"
              >
                <path d="M21 4V6H20L15 13.5V22H9V13.5L4 6H3V4H21ZM6.4037 6L11 12.8944V20H13V12.8944L17.5963 6H6.4037Z"></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Daftar Influencers */}
        <div className="mt-20 flex flex-wrap justify-center gap-10 md:gap-20">
          {isLoading ? (
            <Loader />
          ) : tempKOL.length > 0 ? (
            tempKOL.map((influencer) => {
              const {
                id_kol,
                nama_lengkap,
                jenis_kelamin,
                gambar_utama,
                nama_provinsi,
                media_sosial: medsos,
              } = influencer;

              // Mendapatkan salah satu platform media sosial yang follower nya terbanyak aja
              const findMostFollowers = medsos.reduce((max, current) =>
                current.followers > max.followers ? current : max,
              );

              // Mendapatkan salah satu platform media sosial berdasarkan filter
              const parameterPlatform = queryParams.get("platform");
              const filteredPlatform = medsos.filter(
                (item) => item.id_platform === parameterPlatform,
              );

              const socialMediaItem =
                filteredPlatform.length > 0
                  ? filteredPlatform[0]
                  : findMostFollowers;

              const { platform, username, followers } = socialMediaItem;

              let link;
              let icon;
              const platformLowerCase = platform.toLowerCase();

              switch (platformLowerCase) {
                case "instagram":
                  link = `https://www.${platformLowerCase}.com/`;
                  icon = socialMedia.instagramIcon;
                  break;
                case "tiktok":
                  link = `https://www.${platformLowerCase}.com/`;
                  icon = socialMedia.tiktokIcon;
                  break;
                case "youtube":
                  link = `https://www.${platformLowerCase}.com/`;
                  icon = socialMedia.youtubeIcon;
                  break;
                default:
                  link = `https://www.${platformLowerCase}.com/`;
                  icon = socialMedia.instagramIcon;
                  break;
              }

              return (
                <Link
                  to={`/order-influencer/${id_kol}`}
                  key={id_kol}
                  className="flex w-full max-w-72 cursor-pointer flex-col gap-4 bg-white shadow-xl duration-200 ease-in-out hover:-translate-x-1 hover:-translate-y-1 hover:shadow-2xl sm:w-auto sm:min-w-96 sm:max-w-96 sm:flex-row"
                >
                  <img
                    src={gambar_utama}
                    className="bookmark-image h-56 w-full object-cover object-top sm:h-64 sm:w-32"
                    alt={nama_lengkap}
                    title={nama_lengkap}
                    onError={(e) => {
                      e.target.src = "/photo-not-found.png";
                    }}
                  />
                  <div className="overflow-hidden px-3 pb-3 sm:p-0">
                    <div
                      className="flex max-w-52 items-center gap-2 text-primary hover:opacity-70"
                      title={`@${username}`}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        window.open(`${link}${username}`, "_blank");
                      }}
                    >
                      <img
                        src={icon}
                        alt={platform}
                        width={28}
                        height={28}
                        className="h-5 w-5 sm:h-7 sm:w-7"
                      />
                      <span className="line-clamp-1 block text-ellipsis whitespace-nowrap">
                        {username}
                      </span>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-x-4 gap-y-2 font-medium opacity-60 sm:text-lg">
                      <p>{jenis_kelamin || "-"}</p>
                      <p>{nama_provinsi}</p>
                    </div>
                    <div className="mt-3 flex flex-col gap-4">
                      <FollowersAndEngagmenet
                        text={"Followers"}
                        isFollowers
                        value={followers}
                      />
                      <FollowersAndEngagmenet text={"Engagement"} value={"-"} />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <DataNotFound
              className={"text-primary opacity-50"}
              text="Influencer tidak ditemukan"
            />
          )}
        </div>
        {tempKOL.length > 0 && (
          <div className="mt-10 flex items-center justify-between">
            <button
              type="button"
              className={`rounded-full px-6 py-1 ${pagination.currentPage === 1 ? "cursor-not-allowed opacity-50" : "text-primary hover:bg-blue-600 hover:text-white"}`}
              disabled={pagination.currentPage === 1}
              onClick={() => prevOrNextPaginationDataInfluencer(true)}
            >
              Prev
            </button>
            <div>
              <button
                type="button"
                className={`rounded-full px-6 py-1 ${pagination.currentPage === pagination.lastPage ? "cursor-not-allowed opacity-50" : "text-primary hover:bg-blue-600 hover:text-white"}`}
                disabled={pagination.currentPage === pagination.lastPage}
                onClick={() => prevOrNextPaginationDataInfluencer(false)}
              >
                Next
              </button>
              <button
                type="button"
                className={`rounded-full px-6 py-1 ${pagination.currentPage === pagination.lastPage ? "cursor-not-allowed opacity-50" : "text-primary hover:bg-blue-600 hover:text-white"}`}
                disabled={pagination.currentPage === pagination.lastPage}
                onClick={lastPaginationDataInfluencer}
              >
                Last
              </button>
            </div>
          </div>
        )}
      </section>

      {/* Popup set filter */}
      {isOpenSetFilter && (
        <div className="fixed inset-0 left-0 top-0 z-[999] overflow-auto bg-white pb-10 pt-4">
          <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <div className="flex items-center justify-between">
              <span
                className="cursor-pointer text-lg font-semibold uppercase text-gray-800 hover:underline"
                onClick={closePopupSetFilter}
              >
                Cancel
              </span>
              <span className="text-xl font-bold text-primary">Filter</span>
              <span
                className="cursor-pointer text-lg font-semibold uppercase text-red-500 hover:underline"
                onClick={resetFilter}
              >
                Reset
              </span>
            </div>

            <div className="mt-44">
              <select
                className="rounded-full bg-gray-200 p-3 font-medium text-gray-600 outline-none"
                onChange={({ target }) => setFilterOrder(target.value)}
                defaultValue={filterOrder}
              >
                <option hidden defaultValue={"urutkan"}>
                  Urutkan
                </option>
                {sortInfluencerFilter.map((sortInfluencer) => {
                  const { value, label } = sortInfluencer;

                  return (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  );
                })}
              </select>

              <p className="mb-4 mt-8 text-lg font-medium text-primary">
                Youthz Privilege
              </p>
              <button
                type="button"
                className={`flex items-center gap-2 rounded-full px-6 py-3 font-medium capitalize outline-none ${selectedYoutzPrivilege ? "bg-blue-500 text-white hover:bg-blue-600" : "bg-gray-200 text-gray-600 hover:bg-gray-300"}`}
                onClick={() => setSelectedYoutzPrivilege("true")}
              >
                <img src={Crown} alt="crown" width={18} height={18} />
                Youtz Stars
              </button>

              <div>
                <p className="mb-4 mt-8 text-lg font-medium text-primary">
                  Platform
                </p>
                <div className="flex flex-wrap gap-3">
                  {platforms.map((platform) => {
                    const { id_platform, nama_platform } = platform;

                    return (
                      <button
                        type="button"
                        key={id_platform}
                        value={id_platform}
                        className={`rounded-full ${selectedPlatform === id_platform ? "bg-blue-500 text-white hover:bg-blue-600" : "bg-gray-200 text-gray-600 hover:bg-gray-300"} px-10 py-3 font-medium capitalize outline-none`}
                        onClick={selectPlatform}
                      >
                        {nama_platform}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div>
                <p className="mb-4 mt-8 text-lg font-medium text-primary">
                  Daerah Influencers
                </p>
                <div className="flex flex-wrap gap-3">
                  {provinces.map((province) => {
                    const { id_provinsi, nama_provinsi } = province;

                    return (
                      <button
                        type="button"
                        key={id_provinsi}
                        value={id_provinsi}
                        className={`rounded-full ${selectedProvince === id_provinsi ? "bg-blue-500 text-white hover:bg-blue-600" : "bg-gray-200 text-gray-600 hover:bg-gray-300"} px-10 py-3 font-medium capitalize outline-none`}
                        onClick={selectProvince}
                      >
                        {nama_provinsi}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <button
              type="button"
              className="mx-auto mt-10 flex w-full justify-center rounded-full bg-button-set-filter py-4 text-xl font-semibold text-white hover:opacity-80 sm:max-w-80"
              onClick={changeDataFilter}
            >
              Tampilkan
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default InfluencerDetails;
