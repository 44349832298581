import IconFollowers from "../assets/images/logo/followers.jpg";
import IconEngagment from "../assets/images/logo/engagment.jpg";
import { formatNumber } from "../utils";

const FollowersAndEngagment = ({ isFollowers, text, value }) => {
  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2">
      <div className="flex gap-2">
        <img
          src={isFollowers ? IconFollowers : IconEngagment}
          alt="icon"
          width={20}
          height={20}
          className="h-5 w-5"
        />
        <p>{text}</p>
      </div>
      <p className="font-medium opacity-60">
        {isFollowers ? formatNumber(value) : value}
        {isFollowers ? "" : "%"}
      </p>
    </div>
  );
};

export default FollowersAndEngagment;
