// TestimonialSlider.js
import React from "react";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3, // Jumlah testimoni yang ditampilkan sekaligus
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: false,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        adaptiveHeight: true,
        className: "center",
        centerMode: true,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.03,
        slidesToScroll: 1,
        variableWidth: false,
        adaptiveHeight: true,
      },
    },
  ],
};

const TestimonialSlider = ({ testimonials }) => (
  <div className="mx-auto mt-4 w-full pb-6 sm:py-6">
    <Slider {...settings}>
      {testimonials.map((item) => (
        <div key={item.id} className="rounded-lg bg-border-testimonial p-[3px]">
          <div className="focus:border-border-[#2970E6]flex h-full flex-col justify-between rounded-lg border-2 border-transparent bg-white bg-clip-border px-5 pb-8 pt-5 text-center shadow-lg">
            <div className="flex justify-center">
              <img
                src={item.img}
                alt="person"
                className="-mt-24 h-32 w-32 rounded-full object-cover"
                height={128}
                width={128}
                onError={(e) => {
                  e.target.src = "/photo-not-found.png";
                }}
              />
            </div>
            <div>
              <p className="mt-5 text-[#0062D6]">{item.lokasi}</p>
              <h3 className="text-xl font-bold text-gray-900">
                {item.nama_lengkap}
              </h3>
            </div>
            <p className="mt-5 text-gray-600">{item.testimonial}</p>
            <div className="mt-10 flex justify-center">
              <svg
                width="40"
                viewBox="0 0 52 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.875 9.75C0.875 8.35417 1.13542 7.08333 1.65625 5.9375C2.17708 4.77083 2.875 3.78125 3.75 2.96875C4.625 2.13542 5.64583 1.48958 6.8125 1.03125C8 0.572917 9.25 0.34375 10.5625 0.34375C12.0208 0.34375 13.3438 0.572917 14.5312 1.03125C15.7396 1.46875 16.7708 2.10417 17.625 2.9375C18.5 3.75 19.1667 4.73958 19.625 5.90625C20.1042 7.05208 20.3438 8.33333 20.3438 9.75C20.3438 11.1875 20.0729 12.6979 19.5312 14.2812C19.0104 15.8854 18.25 17.4896 17.25 19.0938C16.2708 20.7188 15.0625 22.3229 13.625 23.9062C12.1875 25.5104 10.5625 27.0417 8.75 28.5L4.3125 25.0312C5.14583 24.1562 5.90625 23.3125 6.59375 22.5C7.30208 21.7083 7.9375 20.9062 8.5 20.0938C9.0625 19.3021 9.57292 18.4792 10.0312 17.625C10.5104 16.7917 10.9479 15.8958 11.3438 14.9375L0.875 9.75ZM31.625 9.75C31.625 8.35417 31.8854 7.08333 32.4062 5.9375C32.9271 4.77083 33.625 3.78125 34.5 2.96875C35.375 2.13542 36.3958 1.48958 37.5625 1.03125C38.75 0.572917 40 0.34375 41.3125 0.34375C42.7708 0.34375 44.0938 0.572917 45.2812 1.03125C46.4896 1.46875 47.5208 2.10417 48.375 2.9375C49.25 3.75 49.9167 4.73958 50.375 5.90625C50.8542 7.05208 51.0938 8.33333 51.0938 9.75C51.0938 11.1875 50.8229 12.6979 50.2812 14.2812C49.7604 15.8854 49 17.4896 48 19.0938C47.0208 20.7188 45.8125 22.3229 44.375 23.9062C42.9375 25.5104 41.3125 27.0417 39.5 28.5L35.0625 25.0312C35.8958 24.1562 36.6562 23.3125 37.3438 22.5C38.0521 21.7083 38.6875 20.9062 39.25 20.0938C39.8125 19.3021 40.3229 18.4792 40.7812 17.625C41.2604 16.7917 41.6979 15.8958 42.0938 14.9375L31.625 9.75Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default TestimonialSlider;
