import React, { useState } from "react";

const FaqList = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const faqs = [
    {
      question: "Apa keuntungan bergabung Youtz Media?",
      answer:
        "Bergabung dengan Youtz Media memberikan banyak manfaat, seperti peluang untuk mengembangkan diri melalui kolaborasi kreatif, membangun jaringan dengan anak muda berbakat, dan mendapatkan dukungan untuk ide-ide inovatif. Selain itu, Anda bisa belajar dari mentor, memperluas wawasan, dan mendapatkan eksposur lebih luas untuk karya yang dihasilkan.",
    },
    {
      question: "Bagaimana cara bergabung?",
      answer:
        "Anda dapat bergabung melalui situs resmi Youtz Media dengan mendaftar dan mengikuti petunjuk yang tersedia.",
    },
    {
      question: "Apakah bergabung berbayar?",
      answer:
        "Tidak, bergabung dengan Youtz Media tidak dikenakan biaya. Anda hanya perlu mendaftar dan memulai perjalanan kreatif Anda.",
    },
  ];

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="space-y-10">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`border-faq rounded-3xl border-2 p-6 shadow-lg transition-all duration-300 ${activeIndex === index ? "bg-faq" : "bg-white"}`}
        >
          <div
            className="flex cursor-pointer items-center justify-between gap-2"
            onClick={() => toggleFaq(index)}
          >
            <h5 className="text-lg font-medium text-black/50">
              {faq.question}
            </h5>
            <div className="hover:opacity-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="28"
                height="28"
                fill="rgba(0,0,0,1)"
                className={`transition-transform duration-300 ${
                  activeIndex === index ? "rotate-45" : ""
                }`}
              >
                <path d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path>
              </svg>
            </div>
          </div>
          <div
            className={`mt-2 overflow-hidden text-black/40 transition-all duration-300 ${
              activeIndex === index
                ? "max-h-[500px] opacity-100"
                : "max-h-0 opacity-0"
            }`}
          >
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqList;
