import React from "react";
import { createRoot } from "react-dom/client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";
import "./styles.css";

import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Contact from "./pages/contact";
import Influencer from "./pages/influencer";
import EngRateIg from "./pages/rate_instagram";
import EngRateTt from "./pages/rate_tiktok";
import Faq from "./pages/faq";
import Profile from "./pages/profile";
import InfluencerDetails from "./pages/InfluencerDetails";
import OrderInfluencer from "./pages/OrderInfluencer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/influencer" element={<Influencer />} />
        <Route
          path="/tools/calculator-eng-rate-instagram"
          element={<EngRateIg />}
        />
        <Route
          path="/tools/calculator-eng-rate-tiktok"
          element={<EngRateTt />}
        />
        <Route
          path="/influencer-details/:param?"
          element={<InfluencerDetails />}
        />
        <Route
          path="/influencer-details/:param?"
          element={<InfluencerDetails />}
        />
        <Route path="/order-influencer/:id" element={<OrderInfluencer />} />
      </Routes>
    </Router>
  );
}

createRoot(document.getElementById("root")).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
