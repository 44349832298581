import igLogo from "../assets/images/contact/instagram-line.png";
import ytLogo from "../assets/images/contact/youtube-line.png";
import linkLogo from "../assets/images/contact/linkedin-box-line.png";
import tiktokLogo from "../assets/images/contact/tiktok-line-icon 1.png";

const SocialMedia = () => {
  return (
    <div className="flex items-center justify-start gap-3 sm:gap-4">
      <a href="https://www.instagram.com/youtzmedia/">
        <img src={igLogo} alt="instagram" />
      </a>
      <a href="https://www.youtube.com/@youtzmedia">
        <img src={ytLogo} alt="youtube" />
      </a>
      <a href="https://www.linkedin.com/company/youtz-media/">
        <img src={linkLogo} alt="linkedin" />
      </a>
      <a href="https://www.tiktok.com/@youtzmedia.id">
        <img src={tiktokLogo} alt="tiktok" />
      </a>
    </div>
  );
};

export default SocialMedia;
