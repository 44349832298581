import InstagramIcon from "../assets/images/logo/instagram_outline.png";
import TiktokIcon from "../assets/images/logo/tiktok-square-color-icon.png";

const Platforms = ({ item, username }) => {
  const platform = item.toLowerCase();
  let image;
  let link;

  switch (platform) {
    case "instagram":
      image = (
        <img
          src={InstagramIcon}
          alt={platform}
          width={20}
          height={20}
          className="h-5 w-5"
        />
      );
      link = `https://instagram.com`;
      break;
    case "tiktok":
      image = (
        <img
          src={TiktokIcon}
          alt={platform}
          width={20}
          height={20}
          className="h-5 w-5"
        />
      );
      link = `https://www.tiktok.com`;
      break;
    default:
      image = (
        <img
          src={InstagramIcon}
          alt={platform}
          width={20}
          height={20}
          className="h-5 w-5"
        />
      );
      break;
  }

  return (
    <div className="mt-2">
      <a
        href={`${link}/${username}`}
        className="flex items-center gap-2 text-sm text-white hover:opacity-50"
        target="_blank"
        rel="noreferrer"
      >
        {image}
        {username}
      </a>
    </div>
  );
};

export default Platforms;
