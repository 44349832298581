import { useState } from "react";
import youtzMediaLogo from "../assets/images/youtzmedia.png";
import Input from "./Input";
import { gateway } from "../utils";

const FormEndorse = ({ name, setIsOpenFormEndorse }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [cooperation, setCooperation] = useState("Jasa Influencer - " + name);
  const [linkBrand, setLinkBrand] = useState(window.location.href);

  const closeFormEndorse = () => {
    setIsOpenFormEndorse((isOpen) => !isOpen);
    document.querySelector("html").removeAttribute("style");
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("nama_lengkap", fullName);
    formData.append("email", email);
    formData.append("nomor_whatsapp", whatsappNumber);
    formData.append("nama_bisnis", companyName);
    formData.append("jabatan", position);
    formData.append("kebutuhan_kerjasama", cooperation);
    formData.append("link_brand", linkBrand);

    try {
      const { success } = await gateway(
        "jasa_kreatif/influencer/order",
        "POST",
        formData,
      );

      if (success) {
        // Format pesan WhatsApp
        const message = encodeURIComponent(
          `Halo, mohon maaf mengganggu waktunya. Sebelumnya perkenalkan saya:\n\n` +
            `Nama lengkap: ${fullName}\n` +
            `Email: ${email}\n` +
            `No WhatsApp: ${whatsappNumber}\n` +
            `Nama Perusahaan/Bisnis: ${companyName}\n` +
            `Jabatan: ${position}\n` +
            `Kebutuhan Kerjasama: ${cooperation}\n` +
            `Link Brand: ${linkBrand}\n\n` +
            `Terima kasih atas waktunya kak.`,
        );

        const waNumber = "628123456789"; // Contoh dulu yaaa
        const waLink = `https://wa.me/${waNumber}?text=${message}`;

        // Redirect ke WhatsApp
        window.location.href = waLink;
      } else {
        console.log("Gagal mengirim form");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Terjadi kesalahan saat mengirim form");
      setIsOpenFormEndorse((isOpen) => !isOpen);
      setFullName("");
      setEmail("");
      setWhatsappNumber("");
      setCompanyName("");
      setPosition("");
    }
  };

  return (
    <div className="fixed inset-0 left-0 top-0 z-50 flex h-full w-full items-center justify-center overflow-auto bg-white/20 pt-32 backdrop-blur-sm sm:py-10">
      <form
        className="overflow-auto rounded-3xl bg-gray-100/70 p-7 sm:max-h-[640px] lg:max-h-none"
        method="POST"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col items-center justify-center gap-5">
          <div className="relative flex w-full items-center justify-center">
            <img src={youtzMediaLogo} alt="logo" className="h-16" />
            <button
              type="button"
              className="absolute bottom-0 right-0 top-0"
              onClick={closeFormEndorse}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="rgba(255,0,0,1)"
              >
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
              </svg>
            </button>
          </div>
          <p>Silahkan isi terlebih dahulu form dibawah ini</p>
        </div>

        <div className="mt-6 flex flex-col gap-4">
          <Input
            label={"Nama Lengkap"}
            placeholder={"Masukkan nama lengkap"}
            type={"text"}
            id={"nama_lengkap"}
            value={fullName}
            setValue={setFullName}
          />
          <Input
            label={"Email"}
            placeholder={"Masukkan email"}
            type={"email"}
            id={"email"}
            value={email}
            setValue={setEmail}
          />
          <Input
            label={"No Whatsapp"}
            placeholder={"Masukkan nomor whatsapp"}
            type={"tel"}
            id={"nomor_wa"}
            value={whatsappNumber}
            setValue={setWhatsappNumber}
          />
          <Input
            label={"Nama Perushaan/Bisnis"}
            placeholder={"Masukkan nama perusahaan/bisnis"}
            type={"text"}
            id={"nama_bisnis"}
            value={companyName}
            setValue={setCompanyName}
          />
          <Input
            label={"Jabatan"}
            placeholder={"Masukkan jabatan"}
            type={"text"}
            id={"jabatan"}
            value={position}
            setValue={setPosition}
          />
          <Input
            label={"Kebutuhan Kerjasama"}
            placeholder={"Masukkan kebutuhan kerjsama"}
            type={"text"}
            id={"kerjasama"}
            isDisabled
            value={cooperation}
            setValue={setCooperation}
          />
          <Input
            label={"Link Brand/Produk"}
            placeholder={"Masukkan brand/produk"}
            type={"text"}
            id={"brand"}
            isDisabled
            value={linkBrand}
            setValue={setLinkBrand}
          />

          <button
            type="submit"
            className="rounded-full bg-purple-600 py-1 font-medium text-white hover:bg-purple-700"
          >
            Kirim
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEndorse;
