import React, { useEffect, useState } from "react";

import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";

import { gateway, socialMedia } from "../utils";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import DataNotFound from "../components/DataNotFound";

const Influencer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSpoilerProvince, setIsSpoilerProvince] = useState(true);
  const [categories, setCategories] = useState([]);
  const [kol, setKOL] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);

    const influencers = async () => {
      const data = await gateway("jasa_kreatif/influencer", "POST", {});
      if (data) {
        setCategories(data.kategori);
        setProvinces(data.provinsi);
        setKOL(data.kol);
      }

      setIsLoading(false);
    };

    influencers();
  }, []);

  return (
    <>
      <Navbar />
      <section className="flex h-screen items-center justify-center bg-jumbotron-influencer bg-cover bg-center bg-no-repeat">
        <div className="lg:container">
          <h1 className="text-center text-3xl font-bold text-white lg:text-5xl">
            Influencer Youtz
          </h1>
          <p className="mt-5 text-center text-base text-white lg:text-xl">
            Daftar Influencer dari Youtz dengan berbagai macam kebutuhan
          </p>
        </div>
      </section>

      {/* KATEGORI INFLUENCER */}
      <div id="category" className="w-full pt-12">
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="lg:40 mb-5 mt-8 text-center text-3xl font-bold text-primary lg:text-4xl">
            Kategori Influencer
          </h2>
          <div className="md:mx-auto md:max-w-3xl">
            {isLoading ? (
              <Loader />
            ) : categories.length > 0 ? (
              <div className="my-8 grid grid-cols-2 items-center justify-center justify-items-center gap-4 md:grid-cols-4">
                {categories.map((category) => {
                  const { id_persona, nama_persona, img_persona } = category;

                  return (
                    <Link
                      to={`/influencer-details/?category=${id_persona}`}
                      className="flex flex-col items-center gap-1 hover:opacity-70"
                      key={id_persona}
                    >
                      <img
                        src={img_persona}
                        height={50}
                        alt={nama_persona}
                        className="h-14"
                      />
                      <p className="text-center font-poppins text-[#0E0101]">
                        {nama_persona}
                      </p>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <DataNotFound
                className="text-primary"
                text="Data kategori influencer tidak ada"
              />
            )}
          </div>
        </div>
      </div>

      {/* Cari berdasarkan daerah */}
      <div className="relative pt-12">
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="lg:40 mb-5 mt-8 text-center text-3xl font-bold text-primary lg:text-4xl">
            Cari Berdasarkan Daerah
          </h2>

          <div className="relative">
            {isLoading ? (
              <Loader />
            ) : provinces.length > 0 ? (
              <>
                <div
                  className={`mt-10 flex flex-wrap justify-center gap-5 ${isSpoilerProvince && "max-h-[640px] overflow-hidden"}`}
                >
                  {provinces.map((province) => {
                    const { id_provinsi, nama_provinsi } = province;
                    return (
                      <Link
                        to={`/influencer-details/?province=${id_provinsi}`}
                        key={id_provinsi}
                        className="min-w-48 rounded-full bg-gray-100 px-6 py-4 text-center outline-1 outline-purple-600 duration-100 ease-in hover:bg-purple-600 hover:text-white"
                      >
                        {nama_provinsi}
                      </Link>
                    );
                  })}
                </div>

                {isSpoilerProvince && (
                  <div className="bg-spoiler absolute inset-x-0 bottom-0 flex h-3/4 items-start justify-center backdrop-blur-sm sm:items-center">
                    <button
                      type="button"
                      className="mt-10 rounded-full bg-purple-600 px-8 py-3 text-white transition-all duration-300 ease-in-out hover:bg-purple-700 hover:shadow-lg"
                      onClick={() => setIsSpoilerProvince(false)}
                    >
                      Lihat semua
                    </button>
                  </div>
                )}
              </>
            ) : (
              <DataNotFound
                className={"text-primary"}
                text="Data daerah influencer tidak ada"
              />
            )}
          </div>
        </div>
      </div>

      {/* INFLUENCER TERFAVORIT */}
      <div id="favorite" className="relative w-full pt-12">
        <div className="mx-4 lg:container sm:mx-4 lg:mx-auto lg:px-4">
          <h2 className="lg:40 mb-5 mt-8 text-center text-3xl font-bold text-primary lg:text-4xl">
            Influencer Terfavorit
          </h2>
          <div className="mt-10 flex flex-col items-center lg:pb-14">
            <div>
              {isLoading ? (
                <Loader />
              ) : kol.length > 0 ? (
                <div className="mx-4 grid gap-8 lg:container sm:mx-8 sm:grid-cols-services lg:mx-auto lg:justify-items-center lg:px-4 xl:grid-cols-3">
                  {kol.map((item) => {
                    const {
                      id_kol,
                      gambar_utama,
                      nama_lengkap,
                      nama_persona: categories,
                      media_sosial: medsos,
                    } = item;
                    return (
                      <div
                        className="w-full rounded-xl bg-white shadow-lg sm:w-max"
                        key={id_kol}
                      >
                        <img
                          className="h-96 min-w-80 max-w-80 object-cover"
                          src={gambar_utama}
                          alt="influencer"
                          onError={(e) => {
                            e.target.src = "/photo-not-found.png";
                          }}
                        />
                        <div className="p-4">
                          <h3 className="mb-4 text-lg font-bold sm:text-xl">
                            {nama_lengkap}
                          </h3>
                          <div className="mb-6 flex flex-wrap gap-2">
                            <p className="w-max rounded-xl border border-gray-900 px-3 py-1 text-center text-[0.7rem] text-sm">
                              {categories.map((category) => category)}
                            </p>
                          </div>

                          <div className="flex flex-wrap items-center justify-between gap-y-2">
                            <p className="text-sm font-medium text-gray-500">
                              Influencer Channel
                            </p>
                            {/* Sosmed nya maksimal 3 aja */}
                            <div className="flex flex-wrap gap-2">
                              {medsos.map((item, index) => {
                                const { platform, username } = item;

                                let link;
                                let icon;
                                const platformLowerCase =
                                  platform.toLowerCase();

                                switch (platformLowerCase) {
                                  case "instagram":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.instagramIcon;
                                    break;
                                  case "tiktok":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.tiktokIcon;
                                    break;
                                  case "youtube":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.youtubeIcon;
                                    break;
                                  default:
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.instagramIcon;
                                    break;
                                }

                                return (
                                  <a
                                    href={`${link}${username}`}
                                    title={platform}
                                    className="hover:opacity-75"
                                    key={index}
                                  >
                                    <img
                                      className="w-6"
                                      src={icon}
                                      alt="social-media"
                                    />
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <DataNotFound
                  className="text-primary"
                  text="Data influencer terfavorit tidak ada"
                />
              )}
            </div>
            {kol.length > 0 && (
              <Link
                to={"/influencer-details"}
                className="mt-14 flex items-center justify-center gap-5 rounded-full border border-gray-600 px-16 py-2"
              >
                <p className="text-center font-poppins text-lg font-medium text-gray-600">
                  Lihat semua
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="rgba(0,0,0,1)"
                >
                  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* TENTANG */}
      <div id="about" className="relative w-full pt-12">
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="lg:40 mb-5 mt-8 text-center text-3xl font-bold text-primary lg:text-4xl">
            Tentang Influencer Marketing
          </h2>
          <div className="mb-4 mt-10 justify-between lg:mb-8 lg:flex">
            <div className="mb-4 lg:mb-0 lg:w-[50%]">
              <h3 className="mb-2 text-lg font-bold text-primary">
                Apa itu influencer Marketing?
              </h3>
              <p className="text-base text-primary">
                Influencer marketing adalah strategi pemasaran yang melibatkan
                kolaborasi dengan individu yang memiliki pengaruh kuat di media
                sosial untuk mempromosikan produk atau layanan. Para influencer
                ini memiliki basis pengikut yang loyal dan dapat mempengaruhi
                keputusan pembelian audiens mereka melalui konten yang otentik
                dan menarik.
              </p>
            </div>
            <div className="lg:w-[40%]">
              <h3 className="mb-2 text-lg font-bold text-primary">
                KOL Strategy
              </h3>
              <p className="text-base text-primary">
                Key Opinion Leader (KOL) adalah strategi yang fokus pada
                kerjasama dengan tokoh berpengaruh yang memiliki keahlian di
                bidang tertentu. Berbeda dengan influencer umum, KOL biasanya
                memiliki kredibilitas dan pengetahuan mendalam di industri
                mereka, sehingga dapat memberikan ulasan dan rekomendasi yang
                lebih terpercaya kepada audience mereka.
              </p>
            </div>
          </div>
          <div className="justify-between lg:flex">
            <div className="mb-4 lg:mb-0 lg:w-[50%]">
              <h3 className="mb-2 text-lg font-bold text-primary">
                Daftar Influencer Marketing
              </h3>
              <p className="text-base text-primary">
                Kami menyediakan akses ke beragam kategori influencer yang telah
                diverifikasi, mulai dari micro-influencer hingga
                mega-influencer. Setiap influencer telah melalui proses kurasi
                ketat untuk memastikan kualitas konten dan engagement rate yang
                optimal. Platform kami memungkinkan brand untuk memilih
                influencer berdasarkan niche, demografi pengikut, dan metrics
                performa lainnya, memastikan kolaborasi yang tepat sasaran dan
                efektif untuk kampanye marketing Anda.
              </p>
            </div>
            <div className="lg:w-[40%]">
              <h3 className="mb-2 text-lg font-bold text-primary">
                Influencer tersebar di seluruh Indonesia
              </h3>
              <p className="text-base text-primary">
                Jaringan influencer kami mencakup seluruh wilayah Indonesia,
                dari Sabang sampai Merauke. Dengan jangkauan geografis yang
                luas, brand dapat menjangkau target market di berbagai daerah
                melalui influencer lokal yang memahami karakteristik dan
                preferensi audience setempat. Hal ini memungkinkan kampanye yang
                lebih personal dan relevan dengan konteks budaya lokal.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Influencer;
