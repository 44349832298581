import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import youtzMediaLogo from "../../assets/images/youtzmedia.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Navbar = ({ isHaveBackground = false }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showTools, setShowTools] = useState(false);

  const toggleToolsMobile = () => {
    setShowTools(!showTools);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const toggleTools = () => setIsToolsOpen(!isToolsOpen);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsAtTop(currentScrollPos === 0);

      if (isAtTop || currentScrollPos < prevScrollPos) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
      setPrevScrollPos(currentScrollPos);
      setIsToolsOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const navbarClassName = `fixed w-full z-[999] transition-all duration-500 transform ${
    isHaveBackground
      ? "bg-white"
      : `${isAtTop ? "bg-transparent" : "bg-gradient-to-r from-[#FF5FF9] to-[#839BF1]"}`
  } ${scrolled ? "-translate-y-full" : "translate-y-0"}`;

  return (
    <>
      <div id="navbar" className={navbarClassName}>
        <div className="mx-4 flex items-center justify-between py-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <Link to="/">
            <img src={youtzMediaLogo} className="h-14 sm:h-20" alt="icon" />
          </Link>
          <ul
            className={`hidden gap-12 font-montserrat font-semibold ${isHaveBackground ? "text-[#060303]" : "text-white"} lg:flex`}
          >
            <li
              className={
                location.pathname === "/"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={
                location.pathname === "/influencer" ||
                location.pathname === "/influencer-details"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }
            >
              <Link to="/influencer">Influencer</Link>
            </li>
            <li
              className={
                location.pathname === "/faq"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }
            >
              <Link to="/faq">FAQ</Link>
            </li>
            {/* <li
              className={`relative ${
                location.pathname === "/testimoni"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }`}
            >
              <button onClick={toggleTools} className="focus:outline-none">
                Tools
              </button>
              {isToolsOpen && (
                <ul className="absolute right-0 top-full mt-5 w-[22rem] rounded-lg bg-white text-base text-black shadow-lg">
                  <li className="from-[#BA57F1] to-[#67BEEF] text-sm hover:rounded-t-lg hover:bg-gradient-to-b hover:text-white">
                    <Link
                      to="/tools/calculator-eng-rate-instagram"
                      className="block px-5 py-3"
                    >
                      Kalkulator engagement rate Instagram
                    </Link>
                  </li>
                  <li className="from-[#BA57F1] to-[#67BEEF] text-sm hover:rounded-b-lg hover:bg-gradient-to-b hover:text-white">
                    <Link
                      to="/tools/calculator-eng-rate-tiktok"
                      className="block px-5 py-3"
                    >
                      Kalkulator engagement rate Tiktok
                    </Link>
                  </li>
                </ul>
              )}
            </li> */}
            <li
              className={
                location.pathname === "/profile"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }
            >
              <Link to="/profile">Profile</Link>
            </li>
            {/* <li
              className={
                location.pathname === "/testimoni"
                  ? "text-[#4F4DCA]"
                  : "hover:text-[#4F4DCA]"
              }
            >
              <Link to="/testimoni">Testimoni</Link>
            </li> */}
          </ul>
          <div className="block lg:hidden" onClick={toggleMenu}>
            <svg
              className={`h-10 w-10 cursor-pointer ${isHaveBackground ? "text-[#777]" : "text-white"}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </div>
        </div>
        <div
          className={`absolute h-screen w-[75%] bg-white pl-6 pr-8 pt-8 duration-300 ${isMenuOpen ? "right-0" : "-right-[100%]"}`}
        >
          <ul
            className={`flex flex-col font-montserrat text-sm font-medium text-gray-500`}
          >
            <Link to="/">
              <li>Home</li>
            </Link>
            <hr className="my-3 border-purple-200" />
            <Link to="/influencer">
              <li>Influencer</li>
            </Link>
            <hr className="my-3 border-purple-200" />
            <Link to="/faq">
              <li>FAQ</li>
            </Link>
            <hr className="my-3 border-purple-200" />
            <div>
              <li
                className="mb-2 flex cursor-pointer items-center justify-between"
                onClick={toggleToolsMobile}
              >
                Tools
                {showTools ? <FaChevronUp /> : <FaChevronDown />}
              </li>
              {showTools && (
                <li>
                  <ul className="list-item list-none pl-4 text-xs">
                    <Link to="/tools/calculator-eng-rate-instagram">
                      <li className="mb-2">
                        Kalkulator engagement rate Instagram
                      </li>
                    </Link>
                    <Link to="/tools/calculator-eng-rate-tiktok">
                      <li>Kalkulator engagement rate Tiktok</li>
                    </Link>
                  </ul>
                </li>
              )}
            </div>
            <hr className="my-3 border-purple-200" />
            <Link to="/profile">
              <li>Profile</li>
            </Link>
            <hr className="my-3 border-purple-200" />
            {/* <Link to="/testimoni">
              <li>Testimoni</li>
            </Link> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
