import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import headerImage from "../assets/images/header.png";
import aboutImage from "../assets/images/about.png";

import mailLogo from "../assets/images/contact/mail-line.png";
import mapLogo from "../assets/images/contact/map-pin-line.png";
import waLogo from "../assets/images/contact/whatsapp-line.png";
import influencerLeft from "../assets/images/influencer1.png";
import influencerRight from "../assets/images/influencer2.png";

import Navbar from "../components/navbar/navbar";
// import Navbar2 from '../components/navbar/navbar2';
import Cta from "../components/home/cta";
import Partner from "../components/home/partner";
import Footer from "../components/footer";
import TestimonialSlider from "../components/home/testimonial";

import AOS from "aos";
import "aos/dist/aos.css";
import Jasa from "../components/home/Jasa";
import SocialMedia from "../components/SocialMedia";
import { gateway, socialMedia } from "../utils";
import Loader from "../components/Loader";
import DataNotFound from "../components/DataNotFound";

AOS.init({
  once: true,
  disable: "phone",
});

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [kol, setKOL] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);

    const jasaKreatif = async () => {
      const data = await gateway("jasa_kreatif", "POST", {});
      if (data) {
        setKOL(data.kol);
        setTestimonials(data.testimoni);
      }

      setIsLoading(false);
    };

    jasaKreatif();
  }, []);

  return (
    <>
      <Navbar />

      <div
        id="home"
        className="relative h-screen bg-cover bg-top xl:h-extraScreen"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="flex h-full items-center justify-center bg-gradient-to-tr from-[#CD5AE0bb] via-[#A478C788] to-[#446DD4bb]">
          <div className="mx-4 mt-4 text-white lg:container sm:mx-8 lg:mx-auto lg:px-4 xl:max-w-6xl">
            <h1 className="my-4 text-3xl font-bold lg:text-center lg:text-5xl xl:mt-14">
              Platform <br className="md:hidden" />
              Influencer <br /> Marketing di Indonesia
            </h1>
            <p className="text-base lg:text-center lg:text-xl">
              Selamat datang di Youtz Media Agency, pusat inspirasi dan
              informasi yang bertekad untuk memberdayakan generasi muda! Kami
              adalah agen media yang berkomitmen untuk menciptakan pengalaman
              pemasaran yang menyegarkan dan bermakna bagi pemuda di seluruh
              dunia.
            </p>
            <div className="mt-4 flex justify-center font-semibold text-white sm:mt-10 lg:mt-16">
              <Link
                to="/influencer-details"
                className="rounded-full border border-white px-6 py-2 text-center hover:opacity-65 lg:px-12"
              >
                Temukan Influencer Anda
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Cta />

      <div id="about" className="relative">
        <div className="absolute -right-28 top-0 -z-20 hidden h-[700px] w-[700px] rounded-full bg-[#F118F5] opacity-45 blur-[300px] lg:block"></div>
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2
            className="lg:40 mb-5 mt-20 text-center text-3xl font-bold text-primary sm:mt-24 lg:mb-24 lg:text-4xl"
            data-aos="fade-up"
          >
            Tentang Youtz Media
          </h2>
          <div className="justify-between lg:flex">
            <div className="mb-8 lg:hidden">
              <div>
                <img src={aboutImage} className="w-full" alt="about-us" />
              </div>
            </div>
            <div className="lg:w-[50%] lg:pr-10 xl:pr-16">
              <h3
                className="mb-4 text-2xl font-bold text-primary lg:text-3xl"
                data-aos="fade-right"
              >
                Apa itu{" "}
                <span className="bg-youtz-media-linear bg-ym-linear">
                  Youtz Media?
                </span>
              </h3>
              <p
                className="text-base text-primary lg:text-xl"
                data-aos="fade-right"
                data-aos-delay="500"
              >
                Selamat datang di Youtz Media Agency, pusat inspirasi dan
                informasi yang bertekad untuk memberdayakan generasi muda! Kami
                adalah agen media yang berkomitmen untuk menciptakan pengalaman
                pemasaran yang menyegarkan dan bermakna bagi pemuda di seluruh
                dunia.
              </p>
            </div>
            <div
              className="relative hidden after:absolute after:-top-8 after:left-8 after:h-full after:w-full after:rounded-3xl after:bg-[#D9D9D9] after:opacity-40 lg:mr-8 lg:block lg:w-[50%]"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div>
                <img
                  src={aboutImage}
                  className="relative z-10 w-full"
                  alt="about-us"
                />
              </div>
            </div>
          </div>
        </div>
        <svg
          className="md-bottom-8 absolute -bottom-[113px] -z-10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#BF94D3"
            fillOpacity="1"
            d="M0,288L40,272C80,256,160,224,240,208C320,192,400,192,480,181.3C560,171,640,149,720,128C800,107,880,85,960,106.7C1040,128,1120,192,1200,186.7C1280,181,1360,107,1400,69.3L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div
        id="why"
        className="mt-28 min-h-screen bg-[#BF94D3] pb-48 text-white"
      >
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="text-center text-3xl font-bold lg:pb-8 lg:text-4xl">
            Kenapa Youtz Media?
          </h2>
          <div className="flex flex-col items-center py-8">
            <div className="text-base md:text-lg lg:max-w-[1000px] lg:text-xl">
              <p>
                Youtz Media merupakan sebuah perusahaan media dan jasa kreatif
                yang bernaung di bawah PT. Kreasi Potensi Indonesia, berdasarkan
                akta dan SK pendiriannya, secara umum bergerak pada penyediaan
                jasa media dan manajemen informasi serta berita, konsepsi,
                rancangan, sampai tahap eksekusi dan penyelenggaraan event.
                Youtz Media sendiri berfokus pada beberapa layanan dan jasa :
              </p>
            </div>
            <div className="mt-12 grid gap-14 sm:mt-20 sm:grid-cols-services lg:grid-cols-3 lg:gap-x-5 lg:gap-y-14">
              <Jasa />
            </div>
            <button className="mt-8 w-full rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl bg-white p-4 font-semibold text-primary shadow-lg duration-150 ease-in hover:-translate-y-1 sm:mt-14 sm:w-80">
              Lihat selengkapnya
            </button>
          </div>
        </div>
      </div>
      <svg
        className="absolute -z-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#BF94D3"
          fillOpacity="1"
          d="M0,32L48,58.7C96,85,192,139,288,138.7C384,139,480,85,576,96C672,107,768,181,864,218.7C960,256,1056,256,1152,218.7C1248,181,1344,107,1392,69.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <div id="influencer" className="-mt-32 overflow-hidden">
        <div className="relative z-10 flex flex-col items-center">
          <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h2 className="text-center text-3xl font-bold text-white lg:pb-8 lg:text-4xl">
              Youtz Star
            </h2>
            <p className="mt-1 text-center text-white">Favorite Influencer</p>
            <div className="grid w-full align-middle lg:grid-cols-2 lg:gap-32 lg:px-32">
              <div>
                <div className="flex h-full flex-col items-center justify-between py-8">
                  <img
                    src={influencerLeft}
                    data-aos="fade-right"
                    data-aos-delay="0"
                    data-aos-duration="750"
                    alt="influencer"
                  />
                  <p
                    className="my-5 text-center text-primary"
                    data-aos="fade-right"
                    data-aos-delay="0"
                    data-aos-duration="750"
                  >
                    Youtz Stars adalah platform untuk menemukan influencer
                    favorit yang beragam dan terkemuka. Kami menghubungkan brand
                    dengan influencer yang tepat untuk meningkatkan daya tarik
                    kampanye, menjangkau audiens yang relevan, dan menciptakan
                    kolaborasi yang inspiratif.
                  </p>
                  <button
                    className="w-[12rem] rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl bg-white px-4 py-2 font-semibold text-primary shadow-lg"
                    data-aos="fade-right"
                    data-aos-delay="0"
                    data-aos-duration="750"
                  >
                    Detail
                  </button>
                </div>
              </div>
              <div>
                <div className="flex h-full flex-col items-center justify-between py-8">
                  <img
                    src={influencerRight}
                    data-aos="fade-left"
                    data-aos-delay="0"
                    data-aos-duration="750"
                    alt="influencer"
                  />
                  <p
                    className="my-5 text-center text-primary"
                    data-aos="fade-left"
                    data-aos-delay="0"
                    data-aos-duration="750"
                  >
                    Youtz Stars menghadirkan pilihan influencer terbaik yang
                    menjadi favorit audiens. Dengan jaringan influencer yang
                    luas, kami membantu brand menciptakan kampanye yang menarik
                    dan berkesan melalui kolaborasi yang tepat sasaran.
                  </p>
                  <button
                    className="w-[12rem] rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl bg-gradient-to-br from-[#7D80E0] to-[#C885DE] px-4 py-2 font-semibold text-white shadow-lg"
                    data-aos="fade-left"
                    data-aos-delay="0"
                    data-aos-duration="750"
                  >
                    Detail
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mt-10 flex flex-col items-center lg:pb-14 xl:mt-28">
            <div className="absolute -left-28 top-0 -z-20 hidden h-[600px] w-[600px] rounded-full bg-[#F118F5] opacity-45 blur-[300px] lg:block"></div>

            <div>
              <div className="mx-4 grid gap-8 lg:container sm:mx-8 sm:grid-cols-services lg:mx-auto lg:justify-items-center lg:px-4">
                {isLoading ? (
                  <Loader />
                ) : kol.length > 0 ? (
                  kol.map((item) => {
                    const {
                      id_kol,
                      gambar_utama,
                      nama_lengkap,
                      nama_persona: categories,
                      media_sosial: medsos,
                    } = item;
                    return (
                      <div
                        className="w-full rounded-xl bg-white shadow-lg sm:w-max"
                        key={id_kol}
                      >
                        <img
                          className="h-96 min-w-80 max-w-80 bg-cover object-cover object-center"
                          src={gambar_utama}
                          alt="influencer"
                          onError={(e) => {
                            e.target.src = "/photo-not-found.png";
                          }}
                        />
                        <div className="p-4">
                          <h3 className="mb-4 text-lg font-bold sm:text-xl">
                            {nama_lengkap}
                          </h3>
                          <div className="mb-6 flex flex-wrap gap-2">
                            <p className="w-max rounded-xl border border-gray-900 px-3 py-1 text-center text-[0.7rem] text-sm">
                              {categories.map((category) => category)}
                            </p>
                          </div>

                          <div className="flex flex-wrap items-center justify-between gap-y-2">
                            <p className="text-sm font-medium text-gray-500">
                              Influencer Channel
                            </p>
                            {/* Sosmed nya maksimal 3 aja */}
                            <div className="flex flex-wrap gap-2">
                              {medsos.map((item, index) => {
                                const { platform, username } = item;

                                let link;
                                let icon;
                                const platformLowerCase =
                                  platform.toLowerCase();

                                switch (platformLowerCase) {
                                  case "instagram":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.instagramIcon;
                                    break;
                                  case "tiktok":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.tiktokIcon;
                                    break;
                                  case "youtube":
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.youtubeIcon;
                                    break;
                                  default:
                                    link = `https://www.${platformLowerCase}.com/`;
                                    icon = socialMedia.instagramIcon;
                                    break;
                                }

                                return (
                                  <a
                                    href={`${link}${username}`}
                                    title={platform}
                                    className="hover:opacity-75"
                                    key={index}
                                  >
                                    <img
                                      className="w-6"
                                      src={icon}
                                      alt="social-media"
                                    />
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <DataNotFound
                    className="text-primary"
                    text="Data influencer tidak ada"
                  />
                )}
              </div>
            </div>

            <Link
              to={"influencer-details"}
              className="mt-14 w-full max-w-72 rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl border-2 border-[#AA83DF] px-4 py-2 text-center font-semibold text-[#AA83DF] shadow-lg duration-150 ease-in hover:bg-other-influencer hover:text-white sm:mx-auto sm:mt-20"
            >
              Influencer lainnya
            </Link>
          </div>
        </div>
      </div>

      {/* TESTIMONIAL */}
      <div
        id="kata-mereka"
        className="mx-4 mt-10 overflow-hidden pb-14 sm:py-10 xl:mt-24"
      >
        <div className="flex flex-col items-center lg:pb-12">
          <h2 className="py-4 text-center text-3xl font-bold text-primary lg:text-4xl">
            Apa kata mereka?
          </h2>
          {isLoading ? (
            <Loader />
          ) : testimonials.length > 0 ? (
            <TestimonialSlider testimonials={testimonials} />
          ) : (
            <DataNotFound
              className="text-primary"
              text="Data testimonial tidak ada"
            />
          )}
        </div>
      </div>

      {/* PARTNER KAMI */}
      <Partner />

      {/* FAQ */}
      <div id="faq" className="mt-24 bg-[#F4F8FB] py-14">
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="mb-2 text-3xl font-bold text-primary lg:text-4xl">
            FAQ
          </h2>
          <p className="pb-8 text-[#64748B]">
            Berikut adalah beberapa pertanyaan yang paling sering ditanyakan.
          </p>
          <div className="grid lg:grid-cols-2">
            <div
              className="mb-8 flex"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="750"
            >
              <div className="mr-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFE152] text-2xl">
                  <p>?</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-primary">
                  Apa itu Platform Influencer Marketing?
                </h3>
                <p className="mt-2 text-[#64748B]">
                  Platform kami menghubungkan brand dengan influencer yang
                  sesuai untuk menjalankan kampanye marketing yang efektif dan
                  terukur.
                </p>
              </div>
            </div>

            <div
              className="mb-8 flex"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="750"
            >
              <div className="mr-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFE152] text-2xl">
                  <p>?</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-primary">
                  Bagaimana cara memilih influencer yang tepat?
                </h3>
                <p className="mt-2 text-[#64748B]">
                  Kami menggunakan algoritma cerdas untuk mencocokkan brand Anda
                  dengan influencer berdasarkan audiens, niche, dan performa
                  mereka di media sosial.
                </p>
              </div>
            </div>

            <div
              className="mb-8 flex"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="750"
            >
              <div className="mr-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFE152] text-2xl">
                  <p>?</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-primary">
                  Apa keuntungan menggunakan platform ini?
                </h3>
                <p className="mt-2 text-[#64748B]">
                  Dengan platform kami, Anda dapat menghemat waktu, mendapatkan
                  data analitik mendalam, dan memastikan hasil kampanye
                  influencer yang optimal.
                </p>
              </div>
            </div>

            <div
              className="mb-8 flex"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="750"
            >
              <div className="mr-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFE152] text-2xl">
                  <p>?</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-primary">
                  Berapa biaya untuk menggunakan platform ini?
                </h3>
                <p className="mt-2 text-[#64748B]">
                  Biaya bergantung pada skala kampanye Anda dan layanan yang
                  digunakan. Kami menyediakan berbagai paket untuk memenuhi
                  kebutuhan bisnis Anda.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" className="mt-14">
        <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          <h2 className="mb-2 text-3xl font-bold text-primary lg:text-4xl">
            Kontak kami
          </h2>
          <p className="text-[#64748B]">
            Hubungi kami, jika butuh bantuan lebih lanjut.
          </p>
          <div className="my-8 flex flex-col justify-between gap-12 sm:grid sm:grid-cols-2 lg:grid-cols-[1fr_1.5fr] xl:grid-cols-[1fr_2.5fr]">
            <div
              className="relative h-[32rem] overflow-hidden rounded-lg bg-gradient-to-b from-[#9900EF] to-[#0693E3]"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <div className="m-4 text-sm font-light text-white lg:m-8">
                <div className="flex items-center gap-4 pb-6">
                  <div className="w-8">
                    <img src={waLogo} className="w-full" alt="whatsapp" />
                  </div>
                  <div>
                    <a className="lg:text-base" href="wa.me/6281234567890">
                      +62 812-3456-7890
                    </a>
                  </div>
                </div>
                <div className="flex items-center gap-4 pb-6">
                  <div className="w-8">
                    <img src={mailLogo} className="w-full" alt="email" />
                  </div>
                  <div>
                    <a
                      className="lg:text-base"
                      href="mailto:partnership@youtzmedia.id"
                    >
                      partnership@youtzmedia.id
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="w-8">
                    <img src={mapLogo} className="w-full" alt="map" />
                  </div>
                  <div className="w-[80%]">
                    <p className="pb-2 lg:text-base">Youtz Media Office</p>
                    <p className="lg:text-base">
                      Jl. Cendrawasih VI, RT.004/RW.003, Sawah Baru, Kec.
                      Ciputat, Kota Tangerang Selatan, Banten 15413
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-12 left-4 lg:left-12">
                <SocialMedia />
              </div>
              <div className="absolute -bottom-14 -right-20 h-40 w-40 rounded-full bg-[#FFE152] sm:h-60 sm:w-60 lg:-right-16"></div>
              <div className="absolute bottom-10 right-10 h-14 w-14 rounded-full bg-[#C2E9FF] sm:bottom-24 sm:right-20 sm:h-28 sm:w-28"></div>
            </div>
            <div>
              <h2 className="pb-2 text-2xl font-semibold">Ada pertanyaan?</h2>
              <p className="text-[#64748B]">
                Isi form dibawah ini, untuk mengajukan pertanyaan baru.
              </p>
              <form className="my-4">
                <div className="pb-4">
                  <label htmlFor="name" className="text-xs text-[#64748B]">
                    Nama
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="mt-2 h-8 w-full border-b-2 bg-transparent text-[#64748B] focus:outline-none"
                    placeholder="John Doe"
                    // value={yourName}
                    // onChange={handleChange}
                  />
                </div>
                <div className="pb-4">
                  <label htmlFor="email" className="text-xs text-[#64748B]">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-2 h-8 w-full border-b-2 bg-transparent text-[#64748B] focus:outline-none"
                    placeholder="johndoe@gmail.com"
                    // value={yourName}
                    // onChange={handleChange}
                  />
                </div>
                <div className="pb-4">
                  <label htmlFor="message" className="text-xs text-[#64748B]">
                    Pesan
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="h-36 w-full border-b-2 bg-transparent text-[#64748B] focus:outline-none"
                    placeholder="Ketik pesan Anda disini..."
                    // value={yourName}
                    // onChange={handleChange}
                  ></textarea>
                </div>
                <button className="mt-8 w-full rounded-full bg-gradient-to-b from-[#9900EF] to-[#0693E3] px-16 py-3 text-sm text-white sm:w-auto lg:text-base">
                  Kirim pertanyaan
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
