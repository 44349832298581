import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const OtherProgram = () => {
  const programs = [
    {
      title: "Academy",
      description:
        "Youtz Academy adalah program yang  dijalankan oleh Youtz Media,  menawarkan kelas atau pelatihan  dalam berbagai aspek media,  mungkin termasuk pembuatan  konten digital, strategi pemasaran  media sosial, produksi video, atau  topik lain yang terkait dengan industri  media",
      bgImage: "bg-program-lainnya",
    },
    {
      title: "Mengeksplor",
      description:
        "Youtz Mengeksplore adalah program  company visit, jadi nanti kita akan  berkunjung ke company dan  bereksplorasi disana",
      bgImage: "bg-program-lainnya",
    },
    {
      title: "Mengabdi",
      description:
        "Youtz mengabdi adalah program  dedikasi sosial dari Youtz Media yang  bertujuan untuk memberikan  kontribusi positif kepada masyarakat  melalui berbagai inisiatif kreatif.  Program ini mengintegrasikan seni,  teknologi, dan pendidikan sebagai alat  untuk memajukan suatu daerah.  Melalui kegiatan seperti workshop,  pengembangan keterampilan, dan  proyek seni kolaboratif, Youtz Media  Mengabdi berkomitmen untuk  merangsang perkembangan dan  kesejahteraan masyarakat",
      bgImage: "bg-program-lainnya",
    },
    {
      title: "Dream Summit",
      description:
        "Youtz Dream Summit Youtz Dream  Summit adalah acara yang didesain  untuk menginspirasi dan memotivasi  peserta untuk mencapai impian dan  potensi mereka.",
      bgImage: "bg-program-lainnya",
    },
  ];

  return (
    <Swiper
      slidesPerView={1}
      modules={[Autoplay]}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      loop
      className={`[&>.swiper-wrapper]:grid [&>.swiper-wrapper]:grid-cols-[1fr_1fr_1fr_1fr] [&>.swiper-wrapper]:sm:flex`}
    >
      {programs.map((program, index) => {
        const { title, description, bgImage } = program;

        return (
          <SwiperSlide key={index} className="h-full">
            <div
              key={index}
              className={`${bgImage} flex h-[500px] items-end justify-center bg-cover bg-[center_top] bg-no-repeat lg:h-screen`}
            >
              <div className="sm:px-8px-4 relative z-10 text-center text-white lg:container lg:px-4">
                <h4 className="text-3xl font-medium sm:text-4xl lg:text-5xl">
                  Youtz
                </h4>
                <h4 className="text-4xl font-bold drop-shadow-[0px_4px_3px_rgba(255,255,255,0.5)] sm:text-5xl lg:mx-auto lg:text-6xl">
                  {title}
                </h4>
              </div>
            </div>
            <div className="md:[500px] relative h-full bg-program-lainnya-solid bg-cover bg-bottom bg-no-repeat pb-40 pt-8 before:absolute before:-top-[299px] before:left-0 before:h-[300px] before:w-full before:bg-gradient-black sm:h-[544px] lg:h-96 lg:before:-top-[307px] lg:before:h-4/5">
              <p className="max-w-5xl px-4 text-center text-xl font-medium leading-[2.2] text-white sm:px-8 lg:mx-auto lg:px-4">
                {description}
              </p>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default OtherProgram;
