import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

import campaignCtaImage from "../../assets/images/cta/campaign.png";
import brandCtaImage from "../../assets/images/cta/brand.png";
import influencerCtaImage from "../../assets/images/cta/influencer.png";

const Cta = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the component is in view
  });

  return (
    <>
      <div
        id="cta"
        className="relative mx-auto -mt-24 w-[85%] sm:-mt-32 lg:w-full"
      >
        <div id="cta" className="container mx-auto lg:px-4">
          <div className="rounded-3xl bg-gradient-to-br from-[#B5BAE8] to-[#CF6FD1] py-10 shadow-xl lg:py-6">
            <div className="flex flex-col items-center justify-evenly p-4 md:flex-row">
              <div ref={ref}>
                <div className="flex h-28 w-28 rounded-full bg-[#CA9BE0] p-4">
                  <img src={influencerCtaImage} />
                </div>
                <div className="text-center text-white">
                  <p className="text-4xl font-bold">
                    {inView ? <CountUp end={99000} duration={3} /> : "0"}+
                  </p>
                  <p className="mt-2 text-xl font-medium">Influencer</p>
                </div>
              </div>
              <div className="my-12">
                <div className="flex h-28 w-28 rounded-full bg-[#CA9BE0] p-4">
                  <img src={brandCtaImage} />
                </div>
                <div className="text-center text-white">
                  <p className="text-4xl font-bold">0+</p>
                  <p className="mt-2 text-xl font-medium">Brand</p>
                </div>
              </div>
              <div>
                <div className="flex h-28 w-28 rounded-full bg-[#CA9BE0] p-4">
                  <img src={campaignCtaImage} />
                </div>
                <div className="text-center text-white">
                  <p className="text-4xl font-bold">0+</p>
                  <p className="mt-2 text-xl font-medium">Campaign Post</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cta;
