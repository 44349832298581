import { useEffect, useState } from "react";

const Programs = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [dataPopup, setDataPopup] = useState({});

  useEffect(() => {
    if (isShowPopup) {
      document.body.style.overflow = "hidden";
      document
        .getElementById("navbar")
        .classList.replace("translate-y-0", "-translate-y-full");
    } else {
      document.body.removeAttribute("style");
      document
        .getElementById("navbar")
        .classList.replace("-translate-y-full", "translate-y-0");
    }
  }, [isShowPopup]);

  const programs = [
    {
      title: "Youthz Creative \nIncubator",
      description:
        "Youtz Creative Incubator merupakan Ruang Inkubasi Kreativitas Anak Muda  Indonesia yang dilaksanakan selama 6 bulan. Program ini akan  menitikberatkan kepada para anak muda untuk berkreasi, berinovasi, belajar,  bekerja sama, dan secara berkesempatan langsung untuk mengaplikasikan  kemampuannya dalam ranah profesional, sehingga mampu menciptakan para  Future Youthz Creative Leaders, yang mampu berinovasi, kreatif, mampu  beradaptasi dan agile, profesional, berpikir kritis, serta mampu bekerja sama  dengan baik. \n\nPara peserta terpilih akan mendapatkan program mentoring secara intensif  oleh para pakar, praktisi, serta tim inti Youtz Media. Selain itu, para peserta  secara profesional akan di track melalui standar penilaian, KPI, dan bimbingan  profesional",
      bgImage: "bg-distrik2",
    },
    {
      title: "Youthz \nCommunity Hub",
      description:
        "Youtz Community Hub merupakan  program yang menjadi inkubasi serta  menghimpun dan berkomitmen untuk  menjangkau seluruh komunitas yang  ada di Indonesia. \n\nYoutz Community Hub akan  menjembatani kebutuhan, serta visi  misi komunitas untuk berkembang,  melalui relasi dan model bisnis dan  menghubungkan komunitas dengan  berbagai lini dan pihak, seperti  pemerintah, company, ataupun antar  organisasi. Selain itu, community hub  akan menyediakan development  program, serta program-program yang  dapat menunjang perkembangan  komunitas-komunitas Indonesia  melalui relasi, kreativitas, inovasi, dan  pembelajaran yang menyenangkan.",
      bgImage: "bg-community-hub",
    },
    {
      title: "Youtz Talk",
      description:
        "Youtz Talk merupakan program  rutin Youtz Media, dalam bentuk  Live Instagram bersama para  KOL, Influencer, dan tokoh  publik yang berpengaruh di  bidangnya masing-masin",
      bgImage: "bg-youth-talk",
    },
  ];

  const onShowPopup = ({ target }) => {
    setIsShowPopup((prev) => !prev);

    if (target.tagName === "H4") {
      const text = target.textContent;
      const specificDataProgram = programs.find(
        (program) => program.title === text,
      );
      setDataPopup(specificDataProgram);
    }
  };

  return (
    <div>
      {programs.map((program, index) => {
        const { title, bgImage } = program;

        return (
          <div
            key={index}
            className={`${bgImage} ${index === 0 ? "h-[500px] bg-[center_bottom] lg:h-[1000px]" : "h-[400px] bg-center lg:h-[600px]"} relative flex items-end bg-cover bg-no-repeat pb-12 before:absolute before:inset-0 before:left-0 before:top-0 before:bg-black/30 lg:p-12`}
          >
            <h4
              className="relative cursor-pointer whitespace-pre-line px-4 text-4xl font-extrabold text-white lg:container hover:opacity-75 sm:px-8 sm:text-5xl lg:mx-auto lg:px-4 lg:text-6xl lg:leading-tight"
              onClick={onShowPopup}
            >
              {title}
            </h4>
          </div>
        );
      })}

      {isShowPopup && (
        <div
          className="fixed inset-0 left-0 top-0 z-50 flex items-center justify-center bg-black/50 pt-20 backdrop-blur-sm"
          onClick={onShowPopup}
        >
          <div
            className="h-full max-w-4xl overflow-auto rounded-3xl bg-gradient-to-b from-[#C076ED] to-[#9AB1EA] px-4 pb-20 pt-14 text-white lg:h-auto lg:px-14"
            onClick={(event) => event.stopPropagation()}
          >
            <h5 className="mb-10 text-center text-4xl font-extrabold lg:text-5xl">
              {dataPopup.title}
            </h5>
            <p className="whitespace-pre-line text-lg font-medium leading-loose text-[#FFE5E5]">
              {dataPopup.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Programs;
