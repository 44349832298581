const Jasa = ({ isRounded = true }) => {
  const services = [
    {
      title: "Event Organizer",
      description:
        "Youtz Media menyediakan jasa perencanaan, konsepsi, eksekusi, dan marketing event",
    },
    {
      title: "News Management",
      description:
        "Layanan pengelolaan berita untuk meningkatkan eksposur dan kredibilitas brand Anda",
    },
    {
      title: "Social Media Management",
      description:
        "Pengelolaan media sosial untuk membangun brand dengan konten menarik",
    },
    {
      title: "KOL Management",
      description:
        "Kolaborasi dengan Key Opinion Leaders untuk meningkatkan visibilitas dan citra brand",
    },
    {
      title: "Community Hub Management",
      description:
        "Pengelolaan komunitas untuk meningkatkan loyalitas dan keterikatan audiens",
    },
    {
      title: "Content Production",
      description:
        "Produksi konten berkualitas tinggi untuk meningkatkan brand awareness dan engagement",
    },
  ];

  return services.map((service, index) => {
    const { title, description } = service;

    return (
      <div
        key={title}
        className={`relative min-h-52 ${isRounded ? "rounded-3xl" : ""} bg-white p-5 shadow-md lg:max-w-80`}
      >
        <div className="absolute -top-6 left-0 right-0 mx-auto flex h-12 w-12 items-center justify-center rounded-full border border-slate-400 bg-white md:-top-8 md:h-14 md:w-14 lg:-top-9 lg:-ml-5 lg:h-16 lg:w-16">
          <p
            className={`${(index + 1) % 2 === 0 ? "number-even-text-whyus" : "number-odd-text-whyus"} text-4xl font-semibold text-transparent md:text-5xl lg:text-6xl`}
          >
            {index + 1}
          </p>
        </div>
        <div className="flex h-full flex-col justify-between">
          <p className="mt-5 text-center text-xl font-semibold text-primary">
            {title}
          </p>
          <p className="mt-20 text-center text-slate-800">{description}</p>
        </div>
      </div>
    );
  });
};

export default Jasa;
