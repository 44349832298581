import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const settings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const partners = [
  "Actifed.webp",
  "AQUA.png",
  "ASTRA.png",
  "Astro.png",
  "AZZURA.png",
  "BCA_Syariah.svg",
  "BINAR.png",
  "bni.png",
  "BODREX.png",
  "boncabe-logo.png",
  "CIMB Bank.png",
  "dermis.png",
  "dicoding-header-logo.png",
  "dm.png",
  "ENERGEN.png",
  "ENERVON C.png",
  "FX Pro.png",
  "garnier.png",
  "Gemini.PNG",
  "Google.png",
  "halodoc.png",
  "HANSAPLAST.png",
  "Hi Seoul.png",
  "hidrococo.png",
  "HPV.png",
  "IDX.png",
  "IMG_9928.PNG",
  "IMG_9930.JPG",
  "IMG_9933.JPG",
  "IMG_9934.JPG",
  "INDRIVE.png",
  "JAGO.png",
  "logo.png",
  "MIXAGRIP.png",
  "MSI.png",
  "NARASI.png",
  "OCBC.png",
  "Panadol.JPG",
  "pegadaian.png",
  "PHILLIPS.png",
  "PIJAR.PNG",
  "Promag.png",
  "QUIXX.png",
  "SAYAKAYA.png",
  "SCHOTERS.png",
  "SIDO MUNCUL.png",
  "smartfren.png",
  "XIAOMI.png",
  "XIYUE.png",
];

const Partner = () => {
  return (
    <>
      <div
        id="partner"
        className="mx-4 mt-10 lg:container sm:mx-8 lg:mx-auto lg:px-4 xl:mt-20"
      >
        <h2 className="text-center text-3xl font-bold text-primary lg:text-4xl">
          Telah bekerja sama dengan brand
        </h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={5}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
          loop
          speed={2000}
          modules={[Autoplay]}
          className="mt-8 flex flex-wrap items-start justify-center gap-4 lg:mt-16 lg:gap-12 lg:px-24"
        >
          {partners.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={`/logo-brand/${img}`}
                  className="h-48 flex-shrink-0 object-contain"
                  alt={"partner"}
                  height={200}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default Partner;
