import Footer from "../components/footer";
import Navbar from "../components/navbar/navbar";
import InfluencerProfile1 from "../assets/images/profile/influencer-1.jpg";
import InfluencerProfile2 from "../assets/images/profile/influencer-2.jpg";
import InfluencerProfile3 from "../assets/images/profile/influencer-3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ImageButtonCategory from "../assets/images/profile/tombol-kategori.jpg";
import { gateway } from "../utils";
import { Link } from "react-router-dom";
import { Jasa } from "../components/home";
import RinaldiDistrikBerisik from "../assets/images/profile/portofolio/rinaldi-distrik-berisik.png";
import DistrikBerisik from "../assets/images/profile/portofolio/distrik-berisik.png";
import YoutzSummit from "../assets/images/profile/portofolio/youtz-summit.png";
import RevisiHidup from "../assets/images/profile/portofolio/revisi-hidup.png";
import PortalMedia from "../assets/images/profile/portofolio/portal-media.png";
import YoutzTalk from "../assets/images/profile/portofolio/youtz-talk.png";
import Programs from "../components/Programs";
import YoutzInsight from "../assets/images/profile/portofolio/youtz-insight.png";
import YoutzBedug from "../assets/images/profile/portofolio/mendadak-duduk.png";
import OtherProgram from "../components/OtherProgram";
import Wave from "../assets/images/profile/portofolio/small-wave.png";
import HP from "../assets/images/profile/portofolio/handphone.png";
import YoutzMediaWhite from "../assets/images/profile/portofolio/youtz-media-white.png";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import DataNotFound from "../components/DataNotFound";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);

    const category = async () => {
      const data = await gateway("jasa_kreatif/influencer", "POST", {});
      if (data) {
        setCategories(data.kategori);
      }

      setIsLoading(false);
    };

    category();
  }, []);

  return (
    <>
      <Navbar isHaveBackground />
      <main className="mt-28">
        <section className="bg-[#D9D9D9]">
          <div className="mx-4 flex flex-col items-center justify-center py-40 lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h1 className="text-3xl font-bold">Youtz Media</h1>
            <p className="mx-auto mt-10 max-w-[620px] text-center text-lg font-bold">
              Youtz Media berkomitmen menjangkau jutaan anak muda Indonesia
              melalui kreativitas, inovasi, dan pembelajaran menarik dan terkini
              melalui jasa, layanan, dan program-program yang dimiliki Youtz
              Media.
            </p>
          </div>
        </section>

        <section className="mt-24">
          <div className="mx-4 lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h2 className="max-w-[405px] text-xl font-bold">
              Memiliki 3000++ Influencer dari kategori baik Nano, Mikro, Makro,
              Mega dari Platform Instagram, Tiktok & Youtube.
            </h2>
          </div>
          <div className="mt-10">
            <Swiper
              spaceBetween={14}
              slidesPerView={3}
              centeredSlides={false}
              centerInsufficientSlides={true}
            >
              {[InfluencerProfile1, InfluencerProfile2, InfluencerProfile3].map(
                (image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image}
                      className="h-32 w-64 object-cover sm:h-40 sm:w-80 md:h-48 lg:h-64 lg:w-full xl:h-80"
                      alt={`slide-${index}`}
                    />
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </div>
        </section>

        <section className="mx-4 mt-24 lg:container sm:mx-8 lg:mx-auto lg:px-4">
          {isLoading ? (
            <Loader />
          ) : categories.length > 0 ? (
            <div className="grid gap-y-5 sm:grid-cols-2 lg:grid-cols-3">
              {categories.map((category) => {
                const { id_persona, nama_persona } = category;

                return (
                  <div
                    className="relative flex h-36 w-full justify-center"
                    key={id_persona}
                  >
                    <img
                      src={ImageButtonCategory}
                      alt="button"
                      className="absolute -z-10 h-full w-full"
                    />
                    <Link
                      to={`/influencer-details/?category=${id_persona}`}
                      className="flex h-full w-full -translate-y-4 items-center justify-center text-center text-xl font-bold hover:opacity-60"
                    >
                      {nama_persona}
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <DataNotFound
              className="text-primary"
              text="Data kategori influencer tidak ada"
            />
          )}
        </section>

        <section className="mt-24 bg-linear-jasa-layanan bg-bottom bg-no-repeat pb-5 xl:bg-contain">
          <div className="mx-4 flex flex-col items-center justify-center lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h2 className="text-center text-xl font-bold sm:text-2xl">
              Jasa dan Layanan
            </h2>
            <div className="mt-20 grid gap-14 sm:mt-24 sm:grid-cols-services lg:grid-cols-3 lg:gap-x-5 lg:gap-y-14">
              <Jasa isRounded={false} />
            </div>
          </div>
        </section>

        <section className="mt-24">
          <div className="mx-4 flex flex-col items-center justify-center lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h2 className="text-center text-xl font-bold sm:text-2xl">
              Portofolio
            </h2>
            <h4 className="text-center text-lg sm:text-xl">Event Management</h4>
          </div>

          <div>
            {/* Distrik berisik */}
            <div className="mt-20 flex flex-col xl:flex-row">
              <img
                src={RinaldiDistrikBerisik}
                alt="distrik-berisik"
                className="xl:-ml-40 xl:w-full xl:object-cover"
              />
              <img
                src={DistrikBerisik}
                alt="distrik-berisik"
                className="xl:w-full xl:object-cover"
              />
              <div className="bg-distrik2 bg-cover bg-center px-4 py-10 sm:h-96 sm:px-8 lg:p-10 xl:h-auto xl:w-full xl:bg-left">
                <p className="text-5xl font-medium text-white">Distrik</p>
                <p className="mt-1 text-6xl font-medium text-[#EBFF00]">
                  Berisik
                </p>
                <p className="mt-10 max-w-[500px] text-white xl:text-lg">
                  Distrik Berisik merupakan program festival dan talk show yang
                  mengundang pembicara-pembicara yang berpengaruh dan berani
                  bersuara melalui profesi dan aktivitas mereka. Program ini
                  berhasil diadakan oleh oleh Say It Right dan Youtz Media dari
                  volume 1-5, dengan menjangkau lebih dari 1000 peserta dan
                  lebih dari 50 pembicara berpengaruh di Indonesia
                </p>
              </div>
            </div>

            {/* Youtz Summit */}
            <div className="flex flex-col xl:grid xl:grid-cols-2">
              <div className="h-full w-full bg-[#BF72FA] px-4 py-14 sm:px-8 lg:px-10 xl:bg-transparent xl:bg-youtz-summit-sold xl:bg-cover xl:bg-no-repeat 2xl:px-20">
                <p className="text-4xl font-semibold text-white">Buka Mimpi</p>
                <p className="mt-1 text-4xl font-semibold text-white">
                  Youtz Summit
                </p>
                <p className="mt-5 text-white xl:max-w-[720px] xl:text-lg">
                  Kegiatan kolaborasi yang diinisiasi oleh Beasiswa Rinaldi,
                  Teman Curhat, dan Youtz Media dalam bentuk seminar bertemakan
                  From the dreamer to the winner, dengan dua sub Tema yaitu
                  “Perjuangan Membangun Negeri” dan “Berani Mengekspresikan
                  Diri”, untuk mengajak anak muda agar berani untuk bermimpi dan
                  mewujudkan impiannya serta mendalami potensi untuk membangun
                  negeri.
                </p>
                <p className="mt-4 text-white xl:max-w-[720px] xl:text-lg">
                  Buka Mimpi Youthz Summit pertama dilaksanakan tanggal 30
                  Oktober 2022, mengusung Tema, “From The Dreamer to The Winner”
                  dengan 2500 peserta yang hadir secara online dan 150 peserta
                  hadir secara offline di gedung 165 Jakarta Selatan.
                </p>
              </div>
              <div className="xl:z-20">
                <img
                  src={YoutzSummit}
                  alt="youtz-summit"
                  className="h-calc w-full"
                />
              </div>
            </div>

            {/* Revisi Hidup */}
            <div className="flex flex-col xl:grid xl:grid-cols-[1fr_.8fr]">
              <img
                src={RevisiHidup}
                alt="revisi-hidup"
                className="h-calc2 xl:-z-10 xl:-mt-16 xl:w-full"
              />
              <div className="bg-[#EF5D10] px-4 py-14 sm:px-8 lg:px-10 xl:z-10 xl:-mt-12 xl:bg-transparent xl:bg-revisi-hidup-sold xl:bg-cover xl:bg-no-repeat xl:drop-shadow-xl">
                <p className="text-lg font-medium text-white">Revisi Hidup</p>
                <p className="text-5xl font-semibold text-white">Workshop</p>
                <p className="mt-5 text-lg text-white xl:max-w-[450px] xl:text-2xl">
                  Workshop self development yang berfokus pada perancangan life
                  purpose and self reflection, berkolaborasi dengan Ali Zainal
                  Abidin dan Tim.
                </p>
              </div>
            </div>

            {/* News Management */}
            <div className="flex flex-col xl:-mt-4 xl:grid xl:grid-cols-2 2xl:h-[768px]">
              <div className="w-full bg-news-management px-4 py-20 sm:px-8 lg:px-10 xl:pb-0 xl:pt-28 2xl:px-20">
                <p className="text-4xl font-semibold text-[#FFE91F] xl:text-6xl">
                  News <br /> Management
                </p>
                <p className="mt-5 text-xl text-white lg:text-2xl">
                  (Portal Media Youtz Media)
                </p>
                <p className="mt-6 text-white xl:max-w-[500px]">
                  News Management menyediakan jasa dan layanan berupa peliputan
                  media baik youtz media, maupun media nasional di bawah naungan
                  ataupun kerjasama youtz media. News management juga mencakup
                  proses pengelolaan berita dan informasi, mulai dari penulisan,
                  editing, dan proses release di website Youtz Media dan
                  beberapa media di bawah naungan Portal Youtz Media
                </p>
                <div>
                  <p className="mt-6 text-white">Website Youtz media:</p>
                  <a
                    href="https://youtzmedia.id/"
                    className="mt-2 inline-block rounded-xl bg-white px-6 py-2 text-[#6574E5] duration-150 ease-in hover:opacity-75"
                  >
                    https://youtzmedia.id/
                  </a>
                </div>
              </div>
              <img
                src={PortalMedia}
                alt="portal-media"
                className="w-full object-cover xl:-z-20 xl:-mt-[60px] 2xl:-mt-20"
              />
            </div>

            {/* Youtz Talk */}
            <div>
              <img src={YoutzTalk} alt="youtz-talk" className="w-full" />
            </div>

            {/* KOL Management */}
            <div className="relative w-full bg-kol-management bg-cover pb-64 pt-32 text-white lg:pt-52 2xl:h-[90vh]">
              <div className="flex flex-col items-center justify-center gap-14 px-4 lg:container sm:px-8 lg:mx-auto lg:flex-row lg:gap-48 lg:px-4">
                <div>
                  <p className="text-8xl font-extrabold lg:text-9xl">KOL</p>
                  <p className="text-3xl font-semibold md:text-4xl">
                    Management
                  </p>
                </div>
                <div className="text-xl lg:max-w-xl lg:text-2xl">
                  <p>
                    Youtz Media menyediakan ribuan KOL dan influencer dengan
                    berbagai latar belakang, mulai dari kesehatan, keuangan,
                    edukasi, hiburan, life style, dll.
                  </p>
                  <p className="mt-5">
                    Youtz Media selama tahun 2022-2023 sudah melayani penyediaan
                    dan manajemen KOL sebanyak ribuan projek dan campaign. Youtz
                    Media juga mengembangkan, mengelola, serta mengatur konsep
                    branding bagi KOL prioritas atau eksklusif
                  </p>
                </div>
              </div>
            </div>

            <div className="two-circle relative flex items-center"></div>
          </div>

          {/* Program */}
          <div className="-z-10 -mt-56 bg-[#2D2222] pb-56 pt-72 lg:-mt-40 lg:pt-80 2xl:pb-64 2xl:pt-96">
            <p className="px-4 text-center text-6xl font-extrabold text-[#E2DBDB] drop-shadow-[0px_4px_3px_rgba(255,255,255,0.5)] lg:container sm:px-8 md:text-7xl lg:mx-auto lg:px-4 lg:text-8xl">
              Program
            </p>
          </div>

          {/* 3 Program */}
          <Programs />

          {/* Youtz Insight */}
          <div>
            <div className="flex items-center justify-center bg-[#6081FC] py-28 sm:py-32">
              <div className="px-4 text-[#CCEAFF] lg:container sm:px-8 lg:mx-auto lg:px-4">
                <p className="text-5xl font-semibold sm:text-6xl">Youtz</p>
                <p className="text-6xl font-extrabold sm:text-8xl">Insight</p>
                <p className="mt-6 max-w-2xl text-lg font-semibold">
                  Youtz Insight merupakan program short video yang menjelaskan
                  isu terkini dengan perspektif anak muda secara kritis dan
                  analiti
                </p>
              </div>
            </div>

            <img
              src={YoutzInsight}
              className="h-64 w-full object-cover sm:h-auto"
              alt="youtz-insight"
            />
          </div>

          {/* Youtz Bedug */}
          <div className="bg-gradient-to-tr from-[#4B3E9D] to-[#1A1637] py-32 lg:h-[600px] lg:overflow-hidden lg:py-0">
            <div className="flex items-center gap-x-20 px-4 lg:container sm:px-8 lg:mx-auto lg:px-4 xl:gap-x-24">
              <img
                src={YoutzBedug}
                alt="youtz-bedug"
                className="hidden lg:block"
              />

              <div>
                <p className="text-6xl font-bold text-[#D4E0FF]">Youtz Bedug</p>
                <p className="mt-7 max-w-3xl text-lg font-medium leading-loose text-[#C5DCFF] xl:text-xl xl:leading-[2.2]">
                  Youtz Talk merupakan program rutin Youtz Media, dalam bentuk
                  Live Instagram bersama para KOL, Influencer, dan tokoh publik
                  yang berpengaruh di bidangnya masing-masing
                </p>
              </div>
            </div>
          </div>

          {/* Youtz Umroh */}
          <div className="flex flex-col xl:grid xl:grid-cols-2">
            <div className="bg-no-repeatpy-20 flex items-center justify-center bg-[#CB2F2F] bg-cover bg-bottom py-20 xl:h-[610px] xl:bg-transparent xl:bg-youtz-umroh">
              <p className="px-4 text-xl font-medium leading-loose text-[#EAD0D0] lg:container sm:px-8 lg:mx-auto lg:px-4 xl:px-10 2xl:px-20">
                Youtz Podcast adalah program untuk dipublikasikan di Youtube
                Youtz media, kemudian nanti footage dari podcast tersebut akan
                digabungkan dalam bentuk video yang bakalan di publikasikan di
                akun instagram youtz medi
              </p>
            </div>
            <div className="flex items-center justify-center px-4 py-20 lg:container sm:px-8 lg:mx-auto lg:px-4 xl:px-10 2xl:px-20">
              <div>
                <p className="text-4xl font-medium">
                  Youtz <br />
                  <span className="font-bold">Umroh</span>
                </p>
                <p className="mt-4 text-xl font-medium leading-loose text-black">
                  Youtz Berumroh (Start Feb/March) Youtz Berumroh adalah program
                  kolaborasi atas tawaran yang diberikan kepada kak Inal, yang
                  mana nantinya kita akan buatkan grup khusus untuk orang-orang
                  yang mau ikut, kita tidak menyediakan fasilitas tempat untuk
                  menabung tapi memberikan support mentality untuk menabung
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-24 xl:mt-40">
          <div className="mx-4 flex items-center justify-center lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h2 className="text-center text-xl font-bold sm:text-2xl">
              Program lainnya
            </h2>
          </div>

          <div className="mt-20">
            <OtherProgram />
          </div>
        </section>

        {/* Youtz Goes to University */}
        <section className="relative mt-48 rounded-tr-[40px] bg-gradient-to-b from-[#597EDC] to-[#1044CB] pb-48 text-[#CCEAFF]">
          <div className="grid gap-x-32 gap-y-20 px-4 pt-32 lg:container sm:px-8 lg:mx-auto lg:px-4 xl:grid-cols-2">
            <div>
              <p className="text-4xl font-medium">
                Youtz{" "}
                <span className="font-bold leading-normal">
                  Goes to <br /> University
                </span>
              </p>
              <p className="mt-4 text-xl font-medium leading-[2.2]">
                Youtz Academy adalah program yang dijalankan oleh Youtz Media,
                menawarkan kelas atau pelatihan dalam berbagai aspek media,
                mungkin termasuk pembuatan konten digital, strategi pemasaran
                media sosial, produksi video, atau topik lain yang terkait
                dengan industri media
              </p>
            </div>
            <div>
              <p className="text-4xl font-medium">
                Youtz <br />
                <span className="font-bold leading-normal">Tournament</span>
              </p>
              <p className="mt-4 text-xl font-medium leading-[2.2]">
                Youtz Tournament adalah program besar youtz media yang mana
                nantinya disini akan mengambil 1 atau beberapa cabang olahraga
                dan bisa berkolaborasi dengan universitas atau pihak lainny
              </p>
            </div>

            <img
              src={Wave}
              className="absolute bottom-0 left-0 h-20 w-full sm:h-auto"
              alt="wave"
            />
          </div>
        </section>

        {/* Youtz Mural X Nusantara */}
        <section className="mx-4 mt-32 flex flex-col gap-x-32 gap-y-20 lg:container sm:mx-8 lg:mx-auto lg:px-4 xl:grid xl:grid-cols-2">
          <div>
            <h5 className="text-4xl font-medium leading-normal">
              Youtz{" "}
              <span className="font-bold">
                Entertainment / <br />
                Interface Exhibition
              </span>
            </h5>
            <p className="mt-4 text-xl font-medium leading-loose text-[#4D5962]">
              Youtz Mural Jam menggambarkan acara kolaboratif di mana komunitas
              atau individu dapat bersama-sama mengekspresikan seni melalui
              mural. "Youtz" bisa merujuk pada penyelenggara (Youtz Media),
              "Jamming" menciptakan nuansa yang lebih santai dan eksperimental
              dimana peserta dapat saling berkontribusi dan bersinergi tanpa
              batasan kreatif yang kaku
            </p>
          </div>
          <div>
            <h5 className="text-4xl font-medium leading-normal">
              Youtz{" "}
              <span className="font-bold">
                Mural x <br />
                jam muantara
              </span>
            </h5>
            <p className="mt-4 text-xl font-medium leading-loose text-[#4D5962]">
              Sebuah Exhibition yang bertujuan untuk membedah lagu/Album
              (inovasi baru untuk Youtz Media) yang akan dilaksanakan di akhir
              masa incubato
            </p>
          </div>
        </section>

        {/* Follow us */}
        <section className="mt-32 flex flex-col gap-y-14 lg:container xl:grid xl:grid-cols-[1.4fr_1fr]">
          <img src={HP} alt="hp" className="w-full lg:w-[800px] xl:w-auto" />
          <div className="mx-4 sm:mx-8 sm:flex sm:justify-center lg:mx-0 lg:pr-4">
            <div>
              <p className="text-2xl font-semibold sm:text-4xl">Follow</p>
              <a
                href="https://www.instagram.com/youtzmedia/"
                className="mt-4 block w-max rounded-3xl bg-[#E72E2E] px-7 py-6 text-2xl font-semibold text-white hover:opacity-80 sm:px-14 sm:py-7 sm:text-4xl"
              >
                @YoutzMedia
              </a>
              <p className="mb-8 mt-10 text-2xl font-semibold">
                Kunjungi juga Website kami :
              </p>
              <a
                href="https://youtzmedia.id/"
                className="text-2xl font-semibold hover:opacity-70 sm:text-4xl"
              >
                https://YoutzMedia.id/
              </a>
            </div>
          </div>
        </section>

        {/* Footer profile */}
        <section className="flex h-[480px] items-center justify-center bg-footer-profile bg-cover bg-top bg-no-repeat sm:h-[640px] xl:-mt-[400px] xl:h-[800px]">
          <img
            src={YoutzMediaWhite}
            alt="logo-youtz-media"
            className="h-48 sm:h-auto"
          />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Profile;
