import instagramOutline from "../assets/images/logo/instagram_outline.png";
import Tiktok from "../assets/images/logo/tiktok-square-color-icon.png";
import Youtube from "../assets/images/logo/youtube.png";

export const socialMedia = {
  instagramIcon: instagramOutline,
  tiktokIcon: Tiktok,
  youtubeIcon: Youtube,
};

export const formatNumber = (num) => {
  if (!num) {
    return 0;
  }

  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return num.toString();
  }
};

export const formatCategory = (name) => {
  return name.toLowerCase().replace(/ & /g, "-").replace(/\s+/g, "-");
};

export const gateway = async (endpoint, method = "GET", body) => {
  try {
    const options = { method };

    // Jika body ada dan bukan GET atau HEAD
    if (body && method !== "GET" && method !== "HEAD") {
      if (body instanceof FormData) {
        options.body = body; // Jangan set Content-Type secara manual untuk FormData
      } else {
        options.body = JSON.stringify(body);
        options.headers = { "Content-Type": "application/json" };
      }
    }

    const response = await fetch(
      `https://youtzmedia.id/api/${endpoint}`,
      options,
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Gagal mendapatkan data dari API: ${error}`);
    return null;
  }
};

export const sortInfluencerFilter = [
  {
    value: "latest",
    label: "Terbaru",
  },
  {
    value: "atoz",
    label: "Nama A-Z",
  },
  {
    value: "ztoa",
    label: "Nama Z-A",
  },
  {
    value: "followers",
    label: "Follower terbanyak",
  },
  {
    value: "engagement",
    label: "Engagement terbanyak",
  },
];
