import { Link, useParams } from "react-router-dom";
import LocationIcon from "../assets/images/location.png";
import { useEffect, useState } from "react";
import Platforms from "../components/Platforms";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { formatNumber, gateway } from "../utils";
import FormEndorse from "../components/FormEndorse";
import Loader from "../components/Loader";
import DataNotFound from "../components/DataNotFound";

const OrderInfluencer = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDetailAbout, setIsOpenDetailAbout] = useState(false);
  const [dataInfluencer, setDataInfluencer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sosmed, setSosmed] = useState([]);
  const [activities, setActivities] = useState([]);
  const [logo, setLogo] = useState([]);
  const [links, setLinks] = useState([]);
  const [isOpenFormEndorse, setIsOpenFormEndorse] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    const getSpecificInfluencer = async () => {
      const data = await gateway(
        `jasa_kreatif/influencer/detail/${id}`,
        "POST",
        {},
      );

      setIsLoading(false);
      if (!data) return;

      setDataInfluencer((prev) => ({
        ...prev,
        kol: data.kol,
        success: data.success,
      }));
      setCategories(data.kol.nama_persona);
      setSosmed(data.kol.media_sosial);

      setActivities(data.kegiatan);
      setLogo(data.logo);
      setLinks(data.postingan);
    };

    getSpecificInfluencer();
  }, []);

  if (!id) {
    return (
      <div className="mt-10 text-center">
        <p>Influencer tidak ditemukan</p>
        <Link
          to={"/influencer-details"}
          className="text-blue-500 underline hover:text-blue-600"
        >
          Cari influencer
        </Link>
      </div>
    );
  }

  const openFormEndorse = () => {
    setIsOpenFormEndorse((isOpen) => !isOpen);
    document.querySelector("html").style = "overflow: hidden";
  };

  return (
    <main>
      <header className="bg-white shadow-lg">
        <div className="mx-4 py-7 lg:container lg:mx-auto lg:px-4">
          <Link
            to={"/influencer-details"}
            className="flex items-center justify-end gap-1 text-sm font-semibold text-primary/60 hover:opacity-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              fill="rgba(0,0,0,0.6)"
            >
              <path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"></path>
            </svg>
            back to influencer
          </Link>
        </div>
      </header>
      {isLoading ? (
        <Loader />
      ) : dataInfluencer.success ? (
        <section className="md:mx-auto md:max-w-md">
          <img
            src={dataInfluencer["kol"].gambar_utama}
            className="h-[448px] w-full object-cover object-top"
            loading="lazy"
            height={448}
            alt="influencer"
            onError={(e) => {
              e.target.src = "/photo-not-found.png";
            }}
          />
          <div className="mx-4 mt-7 md:mx-0">
            <p className="text-3xl font-bold text-primary lg:text-4xl">
              {dataInfluencer["kol"].nama_lengkap}
            </p>
            <ul className="mt-7 flex flex-wrap items-center gap-x-10 gap-y-4">
              <li className="border-l-4 border-sky-400 pl-2 font-medium text-primary/50">
                {dataInfluencer["kol"].usia} Tahun
              </li>
              <li className="font-medium text-primary/50">
                {categories.join(" & ")}
              </li>
              <li className="font-medium text-primary/50">
                <div className="flex items-center gap-1">
                  <img
                    src={LocationIcon}
                    alt="location-icon"
                    height={22}
                    width={19}
                  />
                  {dataInfluencer["kol"].nama_provinsi}
                </div>
              </li>
            </ul>

            {dataInfluencer["kol"].pinned && (
              <div className="mt-7">
                <p className="w-max rounded-full bg-gradient-to-tl from-[#8ED1FC] to-[#9900EF] px-10 py-1 text-center text-sm font-medium text-white">
                  Terfavorit
                </p>
              </div>
            )}
          </div>
        </section>
      ) : (
        <DataNotFound
          text="Influencer tidak ditemukan"
          className={"mt-5 text-primary"}
        />
      )}

      <section className="mx-4 mt-10 pb-10 md:mx-auto md:max-w-md">
        <p className="text-lg font-bold text-primary">Tentang</p>
        <p
          className={`mt-2 ${isOpenDetailAbout ? "max-h-full" : "max-h-24"} overflow-hidden text-sm text-primary/70`}
        >
          {dataInfluencer.success ? dataInfluencer["kol"].deskripsi : "-"}
        </p>
        <button
          className="mt-5 text-sm font-semibold text-purple-600 hover:opacity-75"
          onClick={() => setIsOpenDetailAbout((prev) => !prev)}
        >
          {isOpenDetailAbout ? "Lihat sedikit" : "Lihat lengkap"}
        </button>
        <button
          type="button"
          className="mt-7 w-full rounded-full border-2 border-purple-600 py-3 text-center text-2xl font-medium duration-150 ease-in hover:bg-purple-600 hover:text-white"
          disabled={!dataInfluencer.success}
          onClick={openFormEndorse}
        >
          Endorse Now
        </button>

        {/* Bagian sosial media */}
        <div className="mt-14 flex flex-col gap-10 rounded-2xl bg-purple-500 py-7">
          {sosmed
            .filter((item) => item.username !== "" && item.username !== "@")
            .map((item) => {
              const {
                platform,
                username,
                followers,
                average_like,
                engagement_rate,
                average_comment,
              } = item;

              return (
                <div key={platform}>
                  <h3 className="bg-black/20 px-8 py-2 text-xl font-bold capitalize text-white">
                    {platform}
                  </h3>
                  <div className="px-8">
                    <Platforms item={platform} username={username} />

                    <div className="mt-10 grid gap-y-6 sm:grid-cols-2">
                      <div>
                        <p className="tracking-wider text-white opacity-70">
                          Followers
                        </p>
                        <p className="mt-2 text-xl font-bold text-white">
                          {formatNumber(followers)}
                        </p>
                      </div>
                      <div>
                        <p className="tracking-wider text-white opacity-70">
                          Engagement rate
                        </p>
                        <p className="mt-2 text-xl font-bold text-white">
                          {engagement_rate}%
                        </p>
                      </div>
                      <div>
                        <p className="tracking-wider text-white opacity-70">
                          Average like
                        </p>
                        <p className="mt-2 text-xl font-bold text-white">
                          {formatNumber(average_like)}
                        </p>
                      </div>
                      <div>
                        <p className="tracking-wider text-white opacity-70">
                          Average comment
                        </p>
                        <p className="mt-2 text-xl font-bold text-white">
                          {formatNumber(average_comment)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="mt-10">
          <div>
            <h3 className="mb-4 text-lg font-medium">Foto Kegiatan</h3>
            {activities.length > 0 ? (
              <Swiper
                spaceBetween={20}
                slidesPerView={1.5}
                loop={true}
                breakpoints={{
                  400: {
                    slidesPerView: 2.2,
                  },
                  576: {
                    slidesPerView: 2.4,
                  },
                  640: {
                    slidesPerView: 2.8,
                  },
                  700: {
                    slidesPerView: 3.3,
                  },
                  768: {
                    slidesPerView: 2.4,
                  },
                }}
              >
                {activities.map((activity, index) => {
                  const { img_kegiatan } = activity;

                  return (
                    <SwiperSlide key={index}>
                      <img
                        key={index}
                        src={img_kegiatan}
                        className="h-28 w-60 object-cover"
                        alt={`slide-${index}`}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              ""
            )}
          </div>
          <div className="mt-8">
            <h3 className="mb-4 text-lg font-medium">Logo Brand</h3>
            {logo.length > 0 ? (
              <Swiper
                spaceBetween={20}
                slidesPerView={1.5}
                loop={true}
                breakpoints={{
                  400: {
                    slidesPerView: 2.2,
                  },
                  576: {
                    slidesPerView: 2.4,
                  },
                  640: {
                    slidesPerView: 2.8,
                  },
                  700: {
                    slidesPerView: 3.3,
                  },
                  768: {
                    slidesPerView: 2.4,
                  },
                }}
              >
                {logo.map((image, index) => {
                  const { img_brand } = image;

                  return (
                    <SwiperSlide key={index}>
                      <img
                        key={index}
                        src={img_brand}
                        className="h-28 w-60 object-contain"
                        alt={`slide-${index}`}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              ""
            )}
          </div>
          <div className="mt-8">
            <h3 className="mb-2 text-lg font-medium">Link Postingan</h3>
            <div className="flex flex-col gap-2">
              {links.map((link, index) => {
                return (
                  <a
                    key={index}
                    href={link}
                    className="text-blue-600 hover:text-blue-500"
                  >
                    {link}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {isOpenFormEndorse && (
        <FormEndorse
          setIsOpenFormEndorse={setIsOpenFormEndorse}
          name={dataInfluencer["kol"].nama_lengkap}
        />
      )}
    </main>
  );
};

export default OrderInfluencer;
