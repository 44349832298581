import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";

import headerImage from "../assets/images/tools/tools-bg.png";
import nandita from "../assets/images/influencer/nandita.png";
import { Link } from "react-router-dom";

const EngRateIg = () => {
  const [username, setUsername] = useState("");
  const [tempUsername, setTempUsername] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [userData, setUserData] = useState({
    followers: "78.5k",
    following: "567",
    posts: "90",
    engagementRate: "0.97%",
    averageLikes: "121.1k",
    averageComments: "1.3k",
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/\s/g, "");
    setTempUsername(value.toLowerCase());
  };

  const checkUsername = (event) => {
    event.preventDefault();

    setShowResults(true);
    setUsername(tempUsername);
  };

  return (
    <>
      <Navbar isHaveBackground />
      <div
        id="home"
        className="relative mt-20 h-screen bg-cover"
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: `center`,
        }}
      >
        <div className="flex h-full items-center justify-center">
          <div className="mx-4 text-primary lg:container sm:mx-8 lg:mx-auto lg:px-4">
            <h1 className="mb-4 text-3xl font-bold md:text-center md:text-4xl xl:mb-6 xl:text-5xl">
              Kalkulator Engagement Rate Instagram
            </h1>
            <p className="text-primary/70 md:text-center md:text-lg xl:mx-auto xl:max-w-4xl">
              Kalkulator Engagement Rate Instagram membantu Anda menghitung
              tingkat interaksi akun Instagram dengan mudah, berdasarkan jumlah
              like, komentar, dan followers.
            </p>

            <form className="mt-8 flex justify-center" onSubmit={checkUsername}>
              <div className="relative w-full rounded-full border-2 border-black bg-white md:max-w-2xl xl:max-w-4xl">
                <div className="flex items-center gap-2 pl-5 pr-2">
                  <span className="text-gray-700 md:text-lg">@</span>
                  <input
                    type="text"
                    value={tempUsername}
                    onChange={handleInputChange}
                    placeholder="username"
                    className="w-full rounded-l-md bg-transparent py-3 focus:outline-none md:py-4 md:text-lg"
                  />
                </div>
                <button
                  type="button"
                  className="absolute right-1 top-1/2 -translate-y-1/2 transform rounded-full bg-gradient-to-b from-[#9900EF] to-[#0693E3] px-8 py-2 font-semibold text-white hover:opacity-70 md:text-lg lg:right-3"
                >
                  Check
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showResults && (
        <div
          id="result"
          className="relative mx-4 mt-5 pt-12 lg:container sm:mx-8 sm:mt-0 lg:mx-auto lg:px-4"
        >
          <div className="flex flex-col items-center justify-between overflow-hidden rounded-3xl shadow-2xl lg:flex-row lg:pl-16 xl:mx-auto xl:max-w-7xl">
            <div className="flex flex-col items-center gap-5 lg:flex-row lg:items-start lg:gap-10 xl:relative xl:z-10">
              <div>
                <img
                  src={nandita}
                  alt="Influencer"
                  className="lg:w-32 xl:w-40"
                />
              </div>
              <div className="flex flex-col lg:block">
                <a
                  href={`https://instagram.com/${username}`}
                  target="_blank"
                  rel="noreferrer"
                  title={username}
                  className="line-clamp-1 whitespace-nowrap text-center text-xl font-semibold text-primary hover:text-sky-600 hover:underline lg:text-left"
                >
                  @{username}
                </a>
                <div className="my-5 flex flex-wrap justify-center gap-8 px-5 text-center sm:gap-12 lg:my-3 lg:justify-start lg:gap-8 lg:px-0">
                  <div className="flex flex-col items-center">
                    <p className="font-medium sm:text-xl">
                      {userData.followers}
                    </p>
                    <p className="mt-1 font-semibold text-[#08020999]">
                      Followers
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="font-medium sm:text-xl">
                      {userData.following}
                    </p>
                    <p className="mt-1 font-semibold text-[#08020999]">
                      Following
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="font-medium sm:text-xl">{userData.posts}</p>
                    <p className="mt-1 font-semibold text-[#08020999]">Post</p>
                  </div>
                </div>
                <button className="hidden rounded-full bg-gradient-to-r from-[#0693E3] to-[#9900EF] px-4 py-1 lg:block xl:mt-6">
                  <Link
                    to={"/influencer"}
                    className="text-sm text-white hover:opacity-70 xl:text-base"
                  >
                    Temukan influencer lain
                  </Link>
                </button>
              </div>
            </div>
            <div className="relative flex w-full flex-col justify-evenly gap-8 rounded-b-3xl bg-[#9859FF] px-5 py-12 lg:w-auto lg:flex-row lg:rounded-b-none lg:rounded-br-3xl lg:rounded-tr-3xl lg:px-16 lg:py-32 xl:pl-32">
              <div className="hidden xl:absolute xl:-left-80 xl:-top-[38px] xl:bottom-0 xl:block xl:h-[402px] xl:w-[402px] xl:rounded-full xl:bg-white"></div>
              <div className="flex flex-col items-center gap-1 text-white">
                <p className="text-center text-4xl font-bold">
                  {userData.engagementRate}
                </p>
                <p>Engagement rate</p>
              </div>
              <div className="flex flex-col items-center gap-1 text-white">
                <p className="text-4xl font-bold">{userData.averageLikes}</p>
                <p>Average likes</p>
              </div>
              <div className="flex flex-col items-center gap-1 text-white">
                <p className="text-4xl font-bold">{userData.averageComments}</p>
                <p>Average comment</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default EngRateIg;
