const Input = ({
  label,
  placeholder,
  type,
  id,
  isDisabled = false,
  value,
  setValue,
}) => {
  const onInput = ({ target }) => {
    setValue(target.value);
  };

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={id} className="text-sm">
        {label}
      </label>
      <input
        type={type}
        id={id}
        placeholder={placeholder}
        disabled={isDisabled || false}
        className="rounded-full border border-gray-700 bg-transparent px-4 py-1 text-sm placeholder-black/50 outline-none"
        required
        value={value}
        onChange={onInput}
      />
    </div>
  );
};

export default Input;
